import {Routes} from '@angular/router';

export const chatRouting: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    loadComponent: () => import('./chat.component').then(m => m.ChatComponent),
    children: [
      {
        path: 'create',
        loadComponent: () => import('./pages/create-chat/create-chat.component').then(m => m.CreateChatComponent)
      },
      {
        path: ':classId/:roomId/edit',
        loadComponent: () => import('./pages/create-chat/create-chat.component').then(m => m.CreateChatComponent)
      },
      {
        path: 'online-lesson',
        loadComponent: () => import('./pages/chat-school/chat-school.component').then(m => m.ChatSchoolComponent)
      },
      {
        path: ':classId/:roomId/details',
        loadComponent: () => import('./pages/chat-details/chat-details.component').then(m => m.ChatDetailsComponent)
      },
      {
        path: ':classId/saves',
        loadComponent: () => import('./pages/dialog/dialog.component').then(m => m.DialogComponent)
      },
      {
        path: ':classId/:roomId/:school',
        loadComponent: () => import('./pages/dialog/dialog.component').then(m => m.DialogComponent)
      }
    ]
  },
];
