import {
  AfterViewInit,
  Component, computed,
  ElementRef, OnDestroy,
  OnInit, signal,
  Signal,
  viewChild,
  WritableSignal
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AcademicYearService, ClassService, DropdownFilterService, SubjectService, TeacherReplacementService} from '@nit-services';
import {FilterDescriptor, SortDescriptor} from '@progress/kendo-data-query';
import {ReplacePaymentTypes, ReplaceReasonTypes} from '@nit-core/global/domain/enums';
import {ReplacementData} from '@nit-core/models/teacher-replacement';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  CellTemplateDirective,
  ColumnComponent, FilterCellTemplateDirective,
  GridComponent,
  KENDO_GRID_DECLARATIONS, NoRecordsTemplateDirective,
  RowClassArgs, StringFilterCellComponent
} from '@progress/kendo-angular-grid';
import {GridBulkDirective} from '@nit-core/directives/grid-bulk.directive';
import {ButtonContextMenuComponent, ContextItem} from '@nit-core/components/buttons/button-context-menu/button-context-menu.component';
import {DeletingReplacementModalComponent} from '../../pages/class-teacher-office/pages/teacher-replacement/pages/creating-teacher-replacement/modal/deleting-replacement-modal/deleting-replacement-modal.component';
import {DropdownFilterComponent} from '@nit-core/components/dropdown-filter/dropdown-filter.component';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {Subject} from '@nit-core/models/subject';
import {Class} from '@nit-models';
import {AuthService} from '@nit-auth';
import {PageTitleComponent} from '../../../../components/page-title/page-title.component';
import {DataPickerComponent} from '@nit-core/components/datepicker/datepicker.component';
import {DatePipe} from '@angular/common';
import {GetTextFromEnumPipe} from '@nit-core/pipes/get-text-from-enum.pipe';
import {CustomCheckboxComponent} from '../../../../components/custom-checkbox/custom-checkbox.component';
import {NoDataComponent} from '@nit-core/components/no-data/no-data.component';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import {of, Subject as rxjsSubject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'nit-replacement-table',
  templateUrl: './replacement-table.component.html',
  styleUrl: './replacement-table.component.scss',
  standalone: true,
  imports: [
    DeletingReplacementModalComponent,
    PageTitleComponent,
    GridBulkDirective,
    DataPickerComponent,
    DatePipe,
    DropdownFilterComponent,
    GetTextFromEnumPipe,
    CustomCheckboxComponent,
    ButtonContextMenuComponent,
    NoDataComponent,
    DynamicComponentDirective,
    KENDO_GRID_DECLARATIONS,
    ColumnComponent,
    CellTemplateDirective,
    FilterCellTemplateDirective,
    StringFilterCellComponent,
    NoRecordsTemplateDirective
  ],
})
export class ReplacementTableComponent implements OnInit, AfterViewInit, OnDestroy {
  gridBulk: Signal<GridBulkDirective> = viewChild(GridBulkDirective);
  _grid: Signal<GridComponent> = viewChild('grid');
  deletingReplacement: Signal<DeletingReplacementModalComponent> = viewChild('deletingReplacement');
  dynamicComponentDirective: Signal<DynamicComponentDirective> = viewChild(DynamicComponentDirective);

  subjectsList: WritableSignal<Subject[]> = signal([]) ;
  classes: WritableSignal<Class[]> = signal([]);
  isReplacementHistory: WritableSignal<boolean> = signal(false);
  isMyReplacement: WritableSignal<boolean> = signal(false);
  replaceReasonType = signal(ReplaceReasonTypes);
  replacePaymentTypes = signal(ReplacePaymentTypes);
  minDate: WritableSignal<Date> = signal(null);
  maxDate: WritableSignal<Date> = signal(null);

  title: Signal<string> = computed(() =>
    this.isMyReplacement() ? 'Мої заміни'
      : this.isReplacementHistory() ? 'Історія замін' : 'Журнал обліку пропущених і замінених уроків'
  );

  userId: string;
  sort: SortDescriptor[] = [{field: 'id', dir: 'asc'}];
  defaultFilter: CompositeFilterDescriptor;

  inAccountingJournal = [
    {name: 'Так', isOfficial: true},
    {name: 'Ні', isOfficial: false}
  ];

  status = [
    {name: 'Проведено', completed: true},
    {name: 'Не проведено', completed: false}
  ];

  lessonsList = [
    {number: 0},
    {number: 1},
    {number: 2},
    {number: 3},
    {number: 4},
    {number: 5},
    {number: 6},
    {number: 7},
    {number: 8},
    {number: 9},
    {number: 10},
    {number: 11},
    {number: 12},
    {number: 13},
    {number: 14},
    {number: 15},
    {number: 16},
    {number: 17},
    {number: 18},
    {number: 19},
    {number: 20},
  ];

  previousDateRange: Date[] | null;

  private readonly _replacementTeacherInputChange$ = new rxjsSubject<Event>();

  constructor(private readonly _activatedRoute: ActivatedRoute,
              private readonly _router: Router,
              private readonly _authService: AuthService,
              public teacherReplacementService: TeacherReplacementService,
              private readonly _dropdownFilterService: DropdownFilterService,
              private readonly _subjectService: SubjectService,
              private readonly _classService: ClassService,
              private readonly _academicYearsService: AcademicYearService) {
    this.userId = this._authService.userId;
    this._activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.isReplacementHistory.set(data?.isReplacementHistory || false);
      this.isMyReplacement.set(data?.isMyReplacement || false);
    });
  }

  ngOnDestroy(): void {
    this._replacementTeacherInputChange$.complete();
  }

  ngOnInit(): void {
    if (!this.isReplacementHistory() || this.isMyReplacement()) {
      this._setDefaultFilters();
    }
    this._getSchoolSubjects();
    this._getClasses();
    this._subscribeOnReplacementTeacherInputChange();
    this._getSemestersRange();
  }

  rowCallback = (context: RowClassArgs): { [key: string]: boolean } => {
    return {danger: context.dataItem.isInvalid};
  };

  openDropdownPopover(event: { isOpened: boolean, dropdownSelect: ElementRef },
    dropdown: DropdownFilterComponent): void {
    this._dropdownFilterService.handleDropdownToggling(event, dropdown, this.dynamicComponentDirective());
  }

  handleClickOutside(filterName: string): void {
    this._dropdownFilterService.handleClickOutside(filterName);
  }

  contextHandler(item: ContextItem, dataItem: ReplacementData): void {
    if (item.mode === 'edit') {
      this._router.navigate(['edit-teacher-replacement'], {
        relativeTo: this._activatedRoute,
        queryParams: {
          id: dataItem.id,
          replaceWithAnotherSubject: dataItem.replaceWithAnotherSubject,
        }
      });
    } else {
      this.deletingReplacement().open(dataItem);
    }
  }

  onDateChange(dateRange: Date[]): void {
    if (this._isSameAsPreviousValue(dateRange)) return;

    if (this.gridBulk()) {
      this.removeFilter();

      if (dateRange?.length === 2) {
        const [dateFrom, dateTo] = dateRange;

        this.addFilter(new Date(dateFrom.setHours(0, 0, 0)).toJSON(), 'gte');
        this.addFilter(new Date(dateTo.setHours(23, 59, 59)).toJSON(), 'lte');
      }
      this.refresh();

      this.previousDateRange = dateRange;
    }
  }

  replacementTeacherInput(e: Event): void {
    this._replacementTeacherInputChange$.next(e);
  }

  addFilter(date: string, operator: string): void {
    this.gridBulk().defaultFilter.push({field: 'date', operator, value: date} as FilterDescriptor);
  }

  removeFilter(): void {
    this.gridBulk().defaultFilter = this.gridBulk().defaultFilter.filter(x => x.field !== 'date');
  }

  refresh(): void {
    this.gridBulk().rebind();
  }

  confirmCompletion(isChecked: boolean, id: string): void {
    this.teacherReplacementService.sendCompletion(id, isChecked)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.refresh());
  }

  ngAfterViewInit(): void {
    if (this.isMyReplacement()) {
      const columns = this._grid().columns.toArray();
      const replacementReasonType = columns.find(column => column.title === 'Причина заміни');
      const paymentType = columns.find(column => column.title === 'Відомості про оплату');
      const lastIndex = columns.length - 1;

      this._grid().reorderColumn(replacementReasonType, lastIndex);
      this._grid().reorderColumn(paymentType, lastIndex, {before: true});
    }
  }

  private _subscribeOnReplacementTeacherInputChange(): void {
    this._replacementTeacherInputChange$.pipe(
      catchError(err => of(err)),
      debounceTime(500),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(data => {
      if (this.gridBulk()) {
        if (!this.gridBulk().compositeDefaultFilter) {
          this.gridBulk().compositeDefaultFilter = {
            logic: 'or',
            filters: []
          } as CompositeFilterDescriptor;
        }
        const mainFilter = this.gridBulk().compositeDefaultFilter;
        mainFilter.filters = [];
        const value = data.target.value;
        if (value) {
          mainFilter.filters.push({
            field: 'replacementTeacher.fullName',
            operator: 'contains',
            value: value
          });
          mainFilter.filters.push({
            field: 'replacementJournals.fullName',
            operator: 'contains',
            value: value
          });
        } else {
          this.gridBulk().compositeDefaultFilter = null;
        }
        this.refresh();
      }
    });
  }

  private _setDefaultFilters(): void {
    if (this.isMyReplacement()) {
      this.defaultFilter = {
        logic: 'or',
        filters: [{
          field: 'replacementTeacher.id',
          operator: 'eq',
          value: this.userId
        },{
          field: 'replacementJournals.teacherId',
          operator: 'eq',
          value: this.userId
        }]
      } as CompositeFilterDescriptor;
    } else {
      const filterTypeDescriptor = {
        field: 'isOfficial',
        operator: 'eq',
        value: true
      } as FilterDescriptor;
      this.defaultFilter = {
        logic: 'and',
        filters: [filterTypeDescriptor]
      } as CompositeFilterDescriptor;
    }
  }

  private _getSchoolSubjects(): void {
    this._subjectService.all({query: {take: '999'}})
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.subjectsList.set(res.data);
      });
  }

  private _getClasses(): void {
    this._classService.all({query: {take: '100'}})
      .pipe(untilDestroyed(this))
      .subscribe(classes => {
        this.classes.set(classes.data.naturalSort('name'));
      });
  }

  private _isSameAsPreviousValue(current: Date[] | null): boolean {
    if (!this.previousDateRange && !current) return true;
    if (!this.previousDateRange || !current) return false;
    if (this.previousDateRange?.length !== current?.length) return false;

    const toDateString = (d: Date) =>
      `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;

    return this.previousDateRange.every((prevDate, i) =>
      toDateString(prevDate) === toDateString(current[i])
    );
  }

  private _getSemestersRange(): void {
    this._academicYearsService.getActive(this.userId, +localStorage.getItem('schoolId'))
      .subscribe(res => {
        if (res) {
          this.minDate.set(new Date(res.yearStart, 8, 1));
          this.maxDate.set(new Date(res.yearEnd, 7, 31));
        }
      });
  }
}
