<div id="certificate-achievement-component"
     [class.no-data]="(viewMode === nushViewModes.Parent || viewMode === nushViewModes.Child) && !hasParentAccess()">
  <nit-loader-content *ngIf="viewMode === nushViewModes.Parent || viewMode === nushViewModes.Child ?
                        ((loading$ | async) === true) : false; else content" />

  <ng-template #content>
    <nit-no-data [hasData]="(viewMode !== nushViewModes.Parent &&
                 viewMode !== nushViewModes.Child) || hasParentAccess()"
                 [text]="schoolSettings.institutionType ? 'Куратором' : 'Класним керівником' + 'ще не надано<br> доступ для перегляду Свідоцтва<br> досягнень'">
      <div class="certificate-achievement">
        <nit-title-section [title]="title"
                           [schoolId]="schoolId"
                           [childId]="childId"
                           [isFifthOrHigherGrade]="certificate?.hasSchedules"
                           [didParentView]="didParentView"
                           [isParent]="viewMode === nushViewModes.Parent">
          <nit-header-menu-actions *ngIf="hasAccessToGeneralAchievements() && student"
                                   [withPdf]="true"
                                   [withXls]="false"
                                   (generateReport)="achievementReportRequest($event.type)">
            <div class="actions-wrapper">
              <ng-container *ngIf="canAddRecommendation">
                <button (click)="!wishTeacher && addWishModal.open(student)">
                  Залишити рекомендацію
                </button>
              </ng-container>
              <button *ngIf="viewMode === nushViewModes.Parent && !wishParent
                        && !student.rule.certificate.hasSchedules"
                      (click)="addWishModal.open(student, 1)">
                Залишити побажання
              </button>
              <button *ngIf="viewMode === nushViewModes.ClassTeacher || viewMode === nushViewModes.Admin"
                      [routerLink]="[classId, 'certificate-acquaintance']">
                Ознайомлення батьків зі свідоцтвом досягнень
              </button>
            </div>
          </nit-header-menu-actions>
        </nit-title-section>

        <div class="d-flex">
          <div class="filter-items">
            <nit-class-dropdown *ngIf="viewMode !== nushViewModes.Child && viewMode !== nushViewModes.Parent"
                                #classDropdown
                                [viewMode]="viewMode"
                                [myClassesOnly]="viewMode === nushViewModes.Teacher"
                                [(ngModel)]="classId"
                                (ngModelChange)="classChanged()" />
            <nit-student-dropdown *ngIf="viewMode !== nushViewModes.Child && viewMode !== nushViewModes.Parent"
                                  #studentDropdown
                                  [classId]="classId"
                                  [childId]="childId"
                                  [students]="students()"
                                  [(ngModel)]="student"
                                  (ngModelChange)="studentChanged()" />
          </div>
          <div *ngIf="viewMode !== nushViewModes.Child" class="d-flex">
            <nit-student-pagination *ngIf="classId && studentDropdown?.value"
                                    [studentDropdown]="studentDropdown" />
          </div>
        </div>

        <kendo-tabstrip *ngIf="classId && student; else empty" #tabs id="certificate-achievement">
          <kendo-tabstrip-tab *ngIf="hasAccessToGeneralAchievements()"
                              title="Характеристика навчальної діяльності"
                              [selected]="hasAccessToGeneralAchievements()">
            <ng-template kendoTabContent>
              <nit-table-controls-wrapper>
                <nit-mark-inform *ngIf="generalAchievements()?.length > 0" />
              </nit-table-controls-wrapper>

              <div class="main-wrapper">
                <div class="characteristics">
                  <nit-certificate-table *ngIf="certificate.hasSchedules; else firstGrades"
                                         [achievements]="generalAchievements()"
                                         [childId]="childId"
                                         [decision]="student"
                                         [maxRating]="schoolSettings.maxRating"
                                         [schoolId]="schoolId"
                                         [rows]="certificate?.generalCharacteristics" />

                  <ng-template #firstGrades>
                    <nit-characteristics-table *ngIf="certificate?.generalCharacteristics &&
                                                 generalAchievements()?.length > 0"
                                               [isTeacherView]="viewMode === nushViewModes.Teacher"
                                               [childId]="childId"
                                               [schoolId]="schoolId"
                                               [decision]="student"
                                               [maxRating]="schoolSettings.maxRating"
                                               [isGeneralCharacteristics]="true"
                                               [achievements]="generalAchievements()"
                                               [markService]="generalAchievementMarksService"
                                               [rows]="certificate?.generalCharacteristics" />
                  </ng-template>
                </div>
                <div *ngIf="viewMode !== nushViewModes.Child && viewMode !== nushViewModes.Parent"
                     class="decision-form">
                  <nit-decision-form [decision]="student"
                                     (submitted)="getStudents()" />
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab title="Характеристика результатів навчання"
                              [disabled]="!hasAccessToGeneralAchievements()"
                              [hidden]="true"
                              [selected]="!hasAccessToGeneralAchievements()">
            <ng-template kendoTabContent>
              <div class="filter-items">
                <nit-education-sphere-dropdown *ngIf="domains"
                                               [domains]="domains"
                                               [(ngModel)]="domain"
                                               (ngModelChange)="domainChanged()" />
                <nit-subject-dropdown *ngIf="subjects"
                                      [subjects]="subjects"
                                      [(ngModel)]="subject"
                                      (ngModelChange)="subjectChanged()" />
              </div>

              <nit-table-controls-wrapper>
                <nit-mark-inform *ngIf="achievements().length > 0" />
              </nit-table-controls-wrapper>

              <div class="main-wrapper"
                   [ngStyle]="{'justify-content': classId && student?.id && subject ? 'left': 'center'}">
                <div class="characteristics">
                  <nit-no-data [hasData]="!!subject"
                               [text]="baseNoDataCharacteristicText + (domains ? ' Освітню галузь та' : '') + ' Предмет'">
                    <nit-learning-outcomes-table *ngIf="subject && achievements()?.length"
                                                 [subject]="subject"
                                                 [childId]="childId"
                                                 [decision]="student"
                                                 [maxRating]="schoolSettings.maxRating"
                                                 [isFifthOrHigher]="certificate.hasSchedules"
                                                 [achievement]="achievements()[0]" />
                  </nit-no-data>
                </div>

                <div *ngIf="hasAccessToGeneralAchievements() && classId && viewMode !== nushViewModes.Child
                       && viewMode !== nushViewModes.Parent"
                     class="decision-form">
                  <nit-decision-form [decision]="student"
                                     (submitted)="getStudents()" />
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>

          <kendo-tabstrip-tab *ngIf="hasAccessToWishes && viewMode !== nushViewModes.Child"
                              title="Побажання/Рекомендації">
            <ng-template kendoTabContent>
              <nit-wish-list [wishParent]="wishParent()"
                             [wishTeacher]="wishTeacher()"
                             [isParent]="viewMode === nushViewModes.Parent"
                             [isAdmin]="viewMode === nushViewModes.Admin"
                             (editEmitted)="addWishModal.open(student, viewMode === nushViewModes.Parent ? 1 : 0,
                               true, viewMode === nushViewModes.Parent ? wishParent() : wishTeacher())"
                             (deleteEmitted)="deleteWishModal.open(viewMode === nushViewModes.Parent ? wishParent() : wishTeacher())" />
              <div *ngIf="!(wishParent() || wishTeacher()) && viewMode !== nushViewModes.Admin &&
                     !student.rule.certificate.hasSchedules"
                   class="add-wish-btn"
                   [class.wish]="viewMode === nushViewModes.Parent">
                <nit-button type="secondary"
                            (click)="viewMode === nushViewModes.Parent ?
                            addWishModal.open(student, 1) : addWishModal.open(student)">
                  <div class="add-wish-button-content">
                    <img src="/assets/icons/plus-no-border.svg" alt="Plus icon">
                    <span>Додати {{viewMode === nushViewModes.Parent ? 'побажання' : 'рекомендацію'}}</span>
                  </div>
                </nit-button>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>

        <ng-template #empty>
          <nit-no-data *ngIf="!classId"
                       [text]="baseNoDataText + 'Клас.'" />
          <nit-no-data *ngIf="classId && !student"
                       [text]="baseNoDataText + 'Учня'" />
        </ng-template>
      </div>
    </nit-no-data>
  </ng-template>
</div>

<nit-add-wish-modal #addWishModal
                    [isParent]="viewMode === nushViewModes.Parent"
                    [schoolId]="schoolId"
                    [childId]="childId"
                    (submitted)="getWishes()" />
<nit-delete-wish-modal #deleteWishModal
                       [isParent]="viewMode === nushViewModes.Parent"
                       [schoolId]="schoolId"
                       (deleted)="getWishes()" />
