import {Routes} from '@angular/router';
import {courseDetailsRouting} from '../../course-details/course-details.routing.module';

export const viewCourseCoreRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./view-course.component').then(m => m.ViewCourseComponent),
  },
  {
    path: 'course-details',
    children: courseDetailsRouting,
    data: {
      breadcrumb: 'Заповнення курсу',
      permissions: ['read:course']
    }
  },
  {
    path: 'test-results',
    loadComponent: () => import('../../test-results/test-results.component').then(m => m.TestResultsComponent),
    data: {
      breadcrumb: 'Результати тестувань',
      permissions: ['read:course']
    }
  },
];
