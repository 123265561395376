import {Component, EventEmitter, inject, Input, OnChanges, Output, signal, SimpleChanges, ViewChild, WritableSignal} from '@angular/core';
import {groupByKeys} from '@nit-core/methods';
import {Achievement, ResultingAchievementMark} from '@nit-core/models/achievement';
import {NushDecision, NushDecisionSubject} from '@nit-core/models/nush-decision';
import {MarkService} from '@nit-core/services';
import {NusPopoverEvent} from '../../../../models/nush.models';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {Grading} from '@nit-core/models';
import {CommonModule} from '@angular/common';
import {NusPopoverService} from '@nit-core/services/utils/nus-popover.service';
import {NoDataComponent} from '@nit-core/components/no-data/no-data.component';
import {TableComponent} from '../../../../components/table/table.component';
import {MarkComponent} from '../../../../components/mark/mark.component';

@Component({
  standalone: true,
  selector: 'nit-learning-outcomes-table',
  templateUrl: './learning-outcomes-table.component.html',
  styleUrl: './learning-outcomes-table.component.scss',
  imports: [
    CommonModule,
    NoDataComponent,
    TableComponent,
    MarkComponent,
    DynamicComponentDirective,
  ],
})
export class LearningOutcomesTableComponent implements OnChanges {
  @ViewChild(DynamicComponentDirective, {static: true}) dynamicComponentDirective: DynamicComponentDirective;
  @Input() decision: NushDecision;
  @Input() achievement: Achievement;
  @Input() subject: NushDecisionSubject;
  @Input() childId: string;
  @Input() isFifthOrHigher: boolean = false;
  @Input() maxRating: number;

  @Output() refresh: EventEmitter<void> = new EventEmitter<void>();

  decisionResult: string = '';
  decisionExists: WritableSignal<boolean> = signal(false);
  grading: Grading = {
    isGradingForbidden: false,
    isLeveledGradingActive: true,
    isVerbalGradingActive: true,
    isDigitalGradingActive: true,
  };
  yearMark: ResultingAchievementMark;
  firstSemesterMark: ResultingAchievementMark;
  secondSemesterMark: ResultingAchievementMark;

  public nusPopoverService: NusPopoverService = inject(NusPopoverService);
  public markService: MarkService = inject(MarkService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.achievement?.currentValue) {
      this.achievement.columns?.forEach(column => {
        column.marks = column.marks.map(mark => {
          return {
            ...mark,
            leveledAssessmentType: mark.leveledMark
          };
        });
      });

      this._prepareSummaryMarks('firstSemesterMark');
      this._prepareSummaryMarks('secondSemesterMark');
      this._prepareSummaryMarks('yearMark');

      this.achievement.columns?.map(col => {
        col.groupedMarks = groupByKeys(col.marks, ['learningOutcomeId']);
      });
    }
    if (changes?.decision?.currentValue) {
      this._formatDecision();
    }
  }

  openPopover(event: NusPopoverEvent): void {
    this.nusPopoverService.openPopover(event, this.dynamicComponentDirective, null, null, false);
  }

  refreshTable(): void {
    this.refresh.emit();
  }

  private _prepareSummaryMarks(field: 'firstSemesterMark' | 'secondSemesterMark' | 'yearMark'): void {
    this[field] = this.achievement[field] ? {
      ...this.achievement[field],
      leveledAssessmentType: this.achievement[field]?.leveledMark
    } : null;
  }

  private _formatDecision(): void {
    let decisionResult = '';
    const decisions = {
      commendationLetter: 'Нагородження Похвальним листом,',
      certificateHonors: 'Видання свідоцтва з відзнакою,',
      nextClass: 'Переведення до наступного класу,',
      repeatCourse: 'Залишення для повторного здобуття загальної середньої освіти,',
      finishedSchool: 'Випущення з навчального закладу,',
      droppedSchool: 'Вибуття з навчального закладу,'
    };

    Object.keys(decisions).forEach(key => {
      if (this.decision[key]) {
        this.decisionExists.set(true);
        decisionResult += decisions[key];
      }
    });

    if (decisionResult.endsWith(',')) {
      decisionResult = decisionResult.replace(/.$/, '.');
    }
    this.decisionResult = decisionResult.split(',').join(', ');
  }
}
