import {Routes} from '@angular/router';
import {viewCourseRouting} from '../../../courses/pages/view-course/view-course.routing';

export const supportCoursesRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('../../../../../private/pages/courses/pages/courses.component').then(m => m.CoursesComponent)
  },
  {
    path: 'create-course',
    loadComponent: () => import('../../../../../private/pages/courses/pages/create-edit-course/create-edit-course.component').then(m => m.CreateEditCourseComponent),
    data: {
      breadcrumb: 'Створити курс',
      permissions: ['create:course']
    }
  },
  {
    path: 'view-course',
    data: {
      permissions: ['read:course'],
      isSauronEye: true,
    },
    children: viewCourseRouting
  }
];
