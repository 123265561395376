import {Routes} from '@angular/router';
import {NushViewModes} from '@nit-core/global/domain/enums';
import {AuthGuard} from '@nit-core/auth/auth.guard';
import {certificateAchievementsRouting} from '../basic-data/pages/nush/pages/certificate-achievement/certificate-achievement-routing';
import {SelfEditProfileGuard} from '@nit-core/guards/self-edit-profile-guard';

export const profileRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('../profile/pages/profile/profile.component').then(m => m.ProfileComponent)
  },
  {
    path: 'edit',
    canActivate: [AuthGuard],
    canDeactivate: [SelfEditProfileGuard],
    data: {
      permissions: ['update:me'],
      isEdit: true,
      breadcrumb: 'Редагування профілю'
    },
    loadComponent: () => import('../profile/pages/profile-edit/profile-edit.component').then(m => m.ProfileEditComponent)
  },
  {
    path: 'edit-child',
    canActivate: [AuthGuard],
    data: {
      permissions: ['get:me'],
      isEdit: true,
      breadcrumb: 'Редагування профілю'
    },
    loadComponent: () => import('../children/pages/child/child.component').then(m => m.ChildComponent)
  },
  {
    path: ':id',
    loadComponent: () => import('../profile/pages/profile/profile.component').then(m => m.ProfileComponent)
  },
  {
    path: ':id/timesheet-student',
    data: {
      breadcrumb: 'Табель'
    },
    loadComponent: () => import('../children/pages/timesheet-student/timesheet-student.component').then( m => m.TimesheetStudentComponent)
  },
  {
    path: ':id/certificate-of-achievements',
    children: certificateAchievementsRouting,
    data: {
      breadcrumb: 'Свідоцтво досягнень',
      viewMode: NushViewModes.Child,
    },
  },
  {
    path: ':id/diagnostic-works',
    loadComponent: () => import('../basic-data/pages/nush/pages/diagnostic-works/diagnostic-works.component').then(m => m.DiagnosticWorksComponent),
    data: {
      breadcrumb: 'Діагностувальні роботи',
      viewMode: NushViewModes.Child,
    },
  },
  {
    path: ':id/students-observations',
    loadComponent: () => import('../basic-data/pages/nush/pages/students-observations/students-observations.component').then(m => m.StudentsObservationsComponent),
    data: {
      breadcrumb: 'Спостереження за учнем',
      viewMode: NushViewModes.Child,
    },
  }
];
