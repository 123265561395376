import {Pipe, PipeTransform} from '@angular/core';
import {ReplacePaymentTypes, ReplaceReasonTypes} from '@nit-core/global/domain/enums';
import {ReplacementData} from '@nit-core/models/teacher-replacement';

@Pipe({
  standalone: true,
  name: 'getTextFromEnum'
})
export class GetTextFromEnumPipe implements PipeTransform {
  ReplaceReasonTypes = ReplaceReasonTypes;
  ReplacePaymentTypes = ReplacePaymentTypes;

  transform(item: number, arrayName: string, dataItem: ReplacementData): string {
    const specialCaseMap = {
      'ReplaceReasonTypes': dataItem?.otherReplacementReason,
      'ReplacePaymentTypes': dataItem?.otherPaymentInformation
    };

    if (item === 1000 && specialCaseMap[arrayName]) {
      return specialCaseMap[arrayName];
    }

    const key = arrayName === 'ReplaceReasonTypes' ? 'replacementReasonType' : 'paymentType';
    const foundItem = this[arrayName].find(el => el[key] === item);

    return foundItem ? foundItem?.text : '';
  }
}
