import {AuthService, LogInModel} from '@nit-auth';
import {NitForm, NValidate} from '@nit-forms';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {WarningService} from '@nit-services';
import {Warning} from '@nit-core/models/warning';
import {WrapperComponent} from '../../components/wrapper/wrapper.component';
import {NitFieldWrapperComponent} from '../../../core/forms/components/field-wrapper/field-wrapper.component';
import {TrimInputDirective} from '../../../core/directives/trim-input.directive';
import {LinkItemComponent} from '../../components/link-item/link-item.component';
import {ButtonComponent} from '../../../core/components/buttons/button/button.component';

@Component({
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  standalone: true,
  imports: [WrapperComponent, FormsModule, ReactiveFormsModule, NitFieldWrapperComponent, TrimInputDirective, LinkItemComponent, ButtonComponent, RouterLink]
})
export class LoginComponent implements OnInit {

  loginForm: NitForm;
  warning: Warning;

  constructor(private readonly _authService: AuthService,
              private readonly _router: Router,
              private readonly _warningService: WarningService,
              private readonly _route: ActivatedRoute) {
    this._route.queryParams.subscribe(queryParams => {
      if (queryParams?.redirect_uri) localStorage.setItem('redirect_uri', JSON.stringify(queryParams?.redirect_uri));
    });
  }

  ngOnInit(): void {
    this._initLoginForm();
    this._initWarning();
  }

  login(): void {
    if (this.loginForm.validate()) {
      this._authService.login(new LogInModel(this.loginForm.value)).subscribe(() => {
        localStorage.removeItem('loginRedirect');
        window.location.href = window.location.origin + '/dashboard';
      }, (e) => this.loginForm.externalValidationHandler(e));
    }
  }

  private _initLoginForm(): void {
    this.loginForm = new NitForm({
      userName: new FormControl('', [NValidate.required, NValidate.email()]),
      password: new FormControl('', [NValidate.required, NValidate.minLen(6), NValidate.maxLen(100)])
    });
  }

  private _initWarning(): void {
    this._warningService.getWarning().subscribe(res => this.warning = res);
  }
}
