<div class="d-flex justify-content-between" style="position: relative">
  <div>
    <nit-page-title>
      {{title}}
    </nit-page-title>
    <div *ngIf="isParent && !isFifthOrHigherGrade"
         class="leave-wish-text">
      Щоб додати побажання/рекомендації щодо освітнього процесу, натисність на<br>
      сірий квадрат у правому верхньому куті  та оберіть 'Залишити побажання'
    </div>

    <ng-container *ngIf="isParent">
      <ng-container *ngIf="!didParentView; else statusViewed">
        <div class="approve">
          <div class="text-block">
            <span class="warning-text">Важливо!</span>
            <span class="simple-text">
              Після перегляду свідоцтва досягнень, встановіть, будь ласка, статус “Ознайомлений”,<br>
              натиснувши кнопку нижче
            </span>
          </div>

          <button class="approve-button" (click)="parentApproveView()">
            З свідоцтвом досягнень ознайомлений
          </button>
        </div>
      </ng-container>

      <ng-template #statusViewed>
        <div class="timesheet-view">
          <span class="main-text">Статус:</span>
          <span class="status">Ознайомлений</span>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <ng-content />
</div>