import {Component, inject, Input, OnChanges, OnInit, QueryList, signal, SimpleChanges, ViewChild, ViewChildren, WritableSignal} from '@angular/core';
import {DynamicComponentDirective} from '@nit-core/directives/dynamic-component.directive';
import {AchievementMarkType, KendoFilterOperator} from '@nit-core/global/domain/enums';
import {AchievementMark, Grading, GroupedOutcomesDictionary, GroupOfOutcomes, GrouppedMarks, NushDecision, NushDecisionLearningOutcome} from '@nit-core/models';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {GeneralAchievementMarkService} from '@nit-core/services';
import {HTTPOptions} from '@nit-core/services/global/http-services/rest.service';
import {MarkComponent} from '../../../../components/mark/mark.component';
import {GeneralAchievement} from '@nit-core/models/achievement';
import {NusPopoverEvent} from '../../../../models/nush.models';
import {CommonModule} from '@angular/common';
import {LoaderContentComponent} from '../../../../../../../../components/loader-content/loader-content.component';
import {NusPopoverService} from '@nit-core/services/utils/nus-popover.service';

@Component({
  selector: 'nit-certificate-table',
  templateUrl: './certificate-table.component.html',
  styleUrl: './certificate-table.component.scss',
  standalone: true,
  imports: [CommonModule, MarkComponent, LoaderContentComponent, DynamicComponentDirective]
})
export class CertificateTableComponent implements OnChanges, OnInit {
  @ViewChild(DynamicComponentDirective) dynamicComponentDirective: DynamicComponentDirective;
  @ViewChildren('mark') markComponents: QueryList<MarkComponent>;

  @Input() rows: NushDecisionLearningOutcome[];
  @Input() achievements: GeneralAchievement[];
  @Input() childId: string;
  @Input() schoolId: number;
  @Input() decision: NushDecision;
  @Input() maxRating: number;

  groupedRows: GroupOfOutcomes[];
  achievementMarkType = AchievementMarkType;
  grading: Grading = {
    isGradingForbidden: false,
    isLeveledGradingActive: false,
    isVerbalGradingActive: true,
    isDigitalGradingActive: false
  };
  isEvaluated: boolean = false;
  evaluatedCount: number = 0;
  marks?: GrouppedMarks<AchievementMark>;
  ungroupedMarks?: GrouppedMarks<AchievementMark>;
  marksLoaded: WritableSignal<boolean> = signal(false);
  firstMarksLoad: boolean = true;
  decisionExists: WritableSignal<boolean> = signal(false);
  decisionResult: string = '';

  public markService: GeneralAchievementMarkService = inject(GeneralAchievementMarkService);
  public nusPopoverService: NusPopoverService = inject(NusPopoverService);

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.achievements?.currentValue?.length && !changes.achievements.firstChange) ||
      (!this.marks && this.achievements?.length)) {
      this.getMarks();
    }
    if (changes?.decision?.currentValue) {
      this._formatDecision();
    }
  }

  ngOnInit(): void {
    this._groupRows();
  }

  getMarks(currentRowId?: string, currentAchievementMarkType?: AchievementMarkType): void {
    this.marksLoaded.set(false);
    this.nusPopoverService.clearPopoverSubscriptions();

    const achievementsId = this.achievements.map(x => x.id).join(',');
    const query = new KendoDataQuery(0, 999);
    const options: HTTPOptions = {};
    query.pushFilters({field: 'achievementId', operator: KendoFilterOperator.In, value: achievementsId});

    if (this.childId) {
      if (this.markService instanceof GeneralAchievementMarkService) {
        query.pushFilters({field: 'userId', operator: KendoFilterOperator.Eq, value: this.childId});
      }

      options.asUserId = this.childId;
      options.asUserSchoolId = this.schoolId;
    }
    options.query = query.query;

    if (currentRowId) {
      this.markComponents.forEach(markComponent => {
        if (markComponent.rowId === currentRowId
          && markComponent.generalAchievementValue !== currentAchievementMarkType) {
          markComponent.form.get('id').patchValue(null, {emitEvent: false});
        }
      });
    }

    this.markService.all(options).subscribe(res => {
      res.data.forEach(mark => mark.isVerbalFormed = true);
      this.ungroupedMarks = new GrouppedMarks<AchievementMark>(res.data, 'achievementId', 'learningOutcomeId');
      this.marks = new GrouppedMarks<AchievementMark>(res.data, 'achievementId', 'learningOutcomeId', 'type');
      this.marksLoaded.set(true);
      this.firstMarksLoad = false;

      this._checkRowsForEvaluation(res.data);
    });
  }

  openPopover(event: NusPopoverEvent): void {
    this.nusPopoverService.openPopover(event, this.dynamicComponentDirective, null, null, false);
  }

  private _groupRows(): void {
    this.groupedRows = Object.values(
      this.rows.reduce((acc: GroupedOutcomesDictionary, current: NushDecisionLearningOutcome) => {
        if (current.groupId in acc) {
          acc[current.groupId].groupedAchievements.push(current);
        } else {
          acc[current.groupId] = {
            groupName: current.groupName,
            groupId: current.groupId,
            groupedAchievements: [current]
          };
        }

        return acc;
      }, {})
    );
  }

  private _checkRowsForEvaluation(marks: AchievementMark[]): void {
    let evaluatedCount = 0;

    this.groupedRows.forEach(groupRow => {
      const isEvaluated = groupRow.groupedAchievements.every(ach => {
        return marks.some(mark => mark.learningOutcomeId === ach.id);
      });
      groupRow.isEvaluated = isEvaluated;
      if (isEvaluated) evaluatedCount++;
    });

    this.evaluatedCount = evaluatedCount;
  }

  private _formatDecision(): void {
    let decisionResult = '';
    const decisions = {
      commendationLetter: 'Нагородження Похвальним листом,',
      certificateHonors: 'Видання свідоцтва з відзнакою,',
      nextClass: 'Переведення до наступного класу,',
      repeatCourse: 'Залишення для повторного здобуття загальної середньої освіти,',
      finishedSchool: 'Випущення з навчального закладу,',
      droppedSchool: 'Вибуття з навчального закладу,'
    };

    Object.keys(decisions).forEach(key => {
      if (this.decision[key]) {
        this.decisionExists.set(true);
        decisionResult += decisions[key];
      }
    });

    if (decisionResult.endsWith(',')) {
      decisionResult = decisionResult.replace(/.$/, '.');
    }
    this.decisionResult = decisionResult.split(',').join(', ');
  }
}
