<ng-container *ngIf="!isFirstMarksLoading">
  <nit-table>
    <tr>
      <th>
        <span>{{subject?.name ? subject?.name : 'Характеристика'}}</span>
      </th>
      <ng-container *ngFor="let achievement of achievements">
        <th *ngIf="!areFifthAndHigher || achievement.name !== 'Річна'; else emptyCell">
          <span>{{achievement.name}}</span>
        </th>
      </ng-container>
    </tr>

    <ng-container *ngFor="let row of rows">
      <tr>
        <td><span>{{row.name}}</span></td>
        <ng-container *ngFor="let achievement of achievements">
          <td *ngIf="!areFifthAndHigher || achievement.name !== 'Річна'; else emptyCell">
            <span>
              <nit-mark colKey='achievementId'
                        rowKey='learningOutcomeId'
                        [rowId]="row?.id"
                        [rate]="marks?.get(achievement?.id, row.id) || {}"
                        [grading]="achievement.grading"
                        [maxRating]="maxRating"
                        [markService]="markService"
                        [generalAchievementValue]="isGeneralCharacteristics ? achievementMarkType.YoungClassesType : null"
                        [isGeneralCharacteristics]="isGeneralCharacteristics"
                        [readOnly]="achievement.readOnly"
                        [additionalValues]="{isFinalMark: false}"
                        [colId]="achievement?.id"
                        [isOpened]="isGeneralCharacteristics ?
                          nusPopoverService.isOpenedStatuses[
                            '_' + row?.id + '|' + achievement?.id + '|' + achievementMarkType.YoungClassesType] :
                          nusPopoverService.isOpenedStatuses['_' + row?.id + '|' + achievement?.id]
                        "
                        (openPopover)="openPopover($event)"
                        (submitted)="getMarks()" />
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </nit-table>

  <nit-table *ngIf="footerRows?.length" [isFooter]="true">
    <tr *ngIf="!areFifthAndHigher; else footerHeaders" class="hide-header"></tr>
    <ng-template #footerHeaders>
      <tr>
        <th class="footer-th"></th>
        <ng-container *ngFor="let achievement of achievements">
          <th>
            <span>{{achievement.name}}</span>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-container *ngFor="let row of footerRows">
      <tr>
        <td><span>{{row.name}}</span></td>
        <ng-container *ngFor="let achievement of achievements">
          <td>
            <span>
              <nit-mark colKey='achievementId'
                        rowKey='learningOutcomeId'
                        [rowId]="row?.id"
                        [colId]="achievement?.id"
                        [markService]="markService"
                        [maxRating]="maxRating"
                        [isGeneralCharacteristics]="isGeneralCharacteristics"
                        [generalAchievementValue]="isGeneralCharacteristics ? achievementMarkType.YoungClassesType : null"
                        [rate]="marks?.marks?.[achievement.id]?.[row.id]?.[0] || {}"
                        [grading]="achievement.finalMarkGrading"
                        [additionalValues]="{isFinalMark: true}"
                        [readOnly]="achievement.readOnly"
                        [isOpened]="nusPopoverService.isOpenedStatuses['_' + row?.id + '|' + achievement?.id]"
                        (openPopover)="openPopover($event)"
                        (submitted)="getMarks()" />
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </nit-table>

  <div *ngIf="decisionExists() && childId" class="decision-wrapper">
    <p class="decision-title">Рішення педагогічної ради про:</p>
    <div class="decision-text">
      <span>{{decisionResult}}</span>
    </div>
  </div>
</ng-container>

<nit-loader-content *ngIf="!(marksLoaded$ | async)" />

<ng-template #emptyCell>
  <th></th>
</ng-template>

<ng-template NitAddRating />
