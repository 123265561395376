import {Routes} from '@angular/router';
import {supportCoursesRoutes} from './pages/support-courses/support-courses.module';

export const supportRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/support/support.component').then(m => m.SupportComponent)
  },
  {
    path: 'courses',
    data: {
      isSauronEye: true,
      permissions: ['read:course'],
      breadcrumb: 'Курси'
    },
    children: supportCoursesRoutes
  },
];

