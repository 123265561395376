import {inject, Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';
import {ProfileEditComponent} from 'src/app/private/pages/profile/pages/profile-edit/profile-edit.component';

@Injectable({
  providedIn: 'root'
})
export class SelfEditProfileGuard implements CanDeactivate<ProfileEditComponent> {
  private readonly _toastr = inject(NitToastr);

  canDeactivate(component: ProfileEditComponent): boolean {
    const result = !component.isSelfEditing || component.form.valid;

    if (!result) {
      this._toastr.error('Для переходу до функціоналу завершіть заповнення профілю.');
    }

    return result;
  }
}
