import {Component, OnInit, ViewChild, WritableSignal, signal} from '@angular/core';
import {NitForm} from '@nit-core/forms';
import {
  FileType,
  KendoFilterOperator,
  NushViewModes
} from '@nit-core/global/domain/enums';
import {Achievement, GeneralAchievement} from '@nit-core/models/achievement';
import {KendoDataQuery} from '@nit-core/models/common/kendo-data-query';
import {
  NushDecision,
  NushDecisionCertificate,
  NushDecisionDomain,
  NushDecisionLearningOutcome,
  NushDecisionSubject
} from '@nit-core/models/nush-decision';
import {ClassDropdownComponent} from '../../components/filter/class-dropdown/class-dropdown.component';
import {
  AchievementService, AchievementWishService,
  GeneralAchievementMarkService, GeneralAchievementService,
  NushDecisionService, ReportService, ScoreCardService,
  UserService
} from '@nit-services';
import {Profile, SchoolInfo, SchoolSettings} from '@nit-core/models';
import {AchievementWishes, WishType} from '@nit-core/models/achievement-wishes';
import {PermissionService} from '@nit-core/permission/permission.service';
import {ActivatedRoute, RouterLink} from '@angular/router';
import {StudentDropdownComponent} from '../../components/filter/student-dropdown/student-dropdown.component';
import {AuthService} from '@nit-core/auth';
import {map} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {getShortNameFromFullName} from '@nit-core/methods';
import {LoaderContentComponent} from '../../../../../../components/loader-content/loader-content.component';
import {NoDataComponent} from '@nit-core/components/no-data/no-data.component';
import {TitleSectionComponent} from './components/title-section/title-section.component';
import {MenuActionsComponent} from '../../../../../../components/header-menu-actions/header-menu-actions.component';
import {FormsModule} from '@angular/forms';
import {StudentPaginationComponent} from '../../components/filter/student-pagination/student-pagination.component';
import {TabStripComponent, TabStripTabComponent, TabContentDirective} from '@progress/kendo-angular-layout';
import {TableControlsWrapperComponent} from '../../components/table/table-controls-wrapper.component';
import {MarkInformComponent} from '../../components/mark-inform/mark-inform.component';
import {CertificateTableComponent} from './components/certificate-table/certificate-table.component';
import {CharacteristicsTableComponent} from './components/characteristics-table/characteristics-table.component';
import {DecisionFormComponent} from '../../components/decision-form/decision-form.component';
import {EducationSphereDropdownComponent} from '../../components/filter/education-sphere-dropdown/education-sphere-dropdown.component';
import {SubjectDropdownComponent} from '../../components/filter/subject-dropdown/subject-dropdown.component';
import {WishListComponent} from './components/wish-list/wish-list.component';
import {ButtonComponent} from '@nit-core/components/buttons/button/button.component';
import {AddWishModalComponent} from './components/add-wish-modal/add-wish-modal.component';
import {DeleteWishModalComponent} from './components/delete-wish-modal/delete-wish-modal.component';
import {NitToastr} from '@nit-core/services/global/nit-toastr.service';
import {CommonModule} from '@angular/common';
import {HTTPOptions} from '@nit-core/services/global/http-services/rest.service';
import {LearningOutcomesTableComponent} from './components/learning-outcomes-table/learning-outcomes-table.component';

@Component({
  templateUrl: './certificate-achievement.component.html',
  styleUrl: './certificate-achievement.component.scss',
  standalone: true,
  imports: [CommonModule,
    LoaderContentComponent,
    NoDataComponent,
    TitleSectionComponent,
    MenuActionsComponent,
    RouterLink,
    ClassDropdownComponent,
    FormsModule,
    StudentDropdownComponent,
    StudentPaginationComponent,
    TabStripComponent,
    TabStripTabComponent,
    TabContentDirective,
    TableControlsWrapperComponent,
    MarkInformComponent,
    CertificateTableComponent,
    CharacteristicsTableComponent,
    DecisionFormComponent,
    EducationSphereDropdownComponent,
    SubjectDropdownComponent,
    WishListComponent,
    ButtonComponent,
    AddWishModalComponent,
    DeleteWishModalComponent, LearningOutcomesTableComponent]
})
export class CertificateAchievementComponent implements OnInit {
  @ViewChild('classDropdown') classDropdown: ClassDropdownComponent;
  @ViewChild('studentDropdown') studentDropdown: StudentDropdownComponent;

  filter: NitForm;
  achievements: WritableSignal<Achievement[]> = signal([]);
  wishParent: WritableSignal<AchievementWishes> = signal(null);
  wishTeacher: WritableSignal<AchievementWishes> = signal(null);
  generalAchievements: WritableSignal<GeneralAchievement[]> = signal([]);
  baseNoDataText: string = 'Для відображення Свідоцтва досягнень, оберіть, будь ласка, ';
  baseNoDataCharacteristicText: string = 'Для відображення Характеристик результатів навчання, оберіть, будь ласка, ';
  currentSchoolInfo?: SchoolInfo;
  classId: string;
  classIds: string[] = [];
  student: NushDecision;
  previousStudentId: string;
  domain: NushDecisionDomain | null;
  subject: NushDecisionSubject | null;
  hasParentAccess: WritableSignal<boolean> = signal(false);
  didParentView: boolean = false;
  schoolId: number;
  childId: string;
  className: string;
  title: string = 'Свідоцтво досягнень';
  learningOutcomesSimple: NushDecisionLearningOutcome[];
  learningOutcomesMain: NushDecisionLearningOutcome[];
  certificate: NushDecisionCertificate;
  child$: Observable<Profile>;
  viewMode: NushViewModes;
  nushViewModes: typeof NushViewModes = NushViewModes;
  currentChild: Profile;
  canAddRecommendation: boolean;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  childProfile: boolean;
  userId: string;
  students: WritableSignal<NushDecision[]> = signal([]);
  schoolSettings: SchoolSettings;
  hasAccessToGeneralAchievements: WritableSignal<boolean> = signal(false);

  get hasAccessToWishes(): boolean {
    return this.viewMode !== this.nushViewModes.Teacher &&
      (this._permissionsService.hasPermission(['read:achievement-wish:all'])
      || (this._permissionsService.hasPermission(['read:achievement-wish']) &&
      this.classIds.includes(this.classId)) || this.viewMode === this.nushViewModes.Parent);
  }

  get domains(): NushDecisionDomain[] | null {
    const domains: NushDecisionDomain[] | undefined = this.certificate?.domains;
    if (!domains || domains?.length < 1) return;

    if (!this.hasAccessToGeneralAchievements()) {
      return domains.filter((domain: NushDecisionDomain) => {
        const subjects = domain.subjects.filter((x: NushDecisionSubject) => this.classDropdown?.canShowAchievement(x.name));

        return subjects.length > 0;
      });
    }

    return domains;
  }

  get subjects(): NushDecisionSubject[] | null {
    const subjects: NushDecisionSubject[] | undefined = this.domain?.subjects;

    if (!subjects || subjects?.length < 1) return;

    if (!this.hasAccessToGeneralAchievements()) {
      return subjects.filter((x: NushDecisionSubject) => this.classDropdown?.canShowAchievement(x.name));
    }

    return subjects;
  }

  constructor(public generalAchievementMarksService: GeneralAchievementMarkService,
              private readonly _achievementsService: AchievementService,
              private readonly _achievementWishesService: AchievementWishService,
              private readonly _route: ActivatedRoute,
              private readonly _authService: AuthService,
              private readonly _generalAchievementsService: GeneralAchievementService,
              private readonly _nushDecisionService: NushDecisionService,
              private readonly _nitToastr: NitToastr,
              private readonly _permissionsService: PermissionService,
              private readonly _reportService: ReportService,
              private readonly _scoreCardsService: ScoreCardService,
              private readonly _userService: UserService) { }

  ngOnInit(): void {
    this.userId = this._authService.userId;
    this.childId = this._route.snapshot.params.id;
    this.childProfile = this.userId === this.childId;
    this.schoolSettings = this._userService.currentSchoolSettings$.value;

    this.viewMode = this._route.snapshot.data.ignoreProfileRole ||
      (!this.childProfile && this._route.snapshot.data.viewMode === this.nushViewModes.Child) ?
      this.nushViewModes.Parent : this._route.snapshot.data.viewMode;

    this._initSchoolId();
    if (this.viewMode === this.nushViewModes.Child || this.viewMode === this.nushViewModes.Parent) {
      this._getChildShortName();
      this._getChildSchoolInfo();
    }

    if (this.viewMode !== this.nushViewModes.Parent && this.viewMode !== this.nushViewModes.Child) {
      this._getCurrentSchoolInfo();
    }
  }

  getStudents(): void {
    if (!this.classId) return;

    const decisionQuery = new KendoDataQuery(0, 999);
    decisionQuery.pushFilters({field: 'classId', operator: KendoFilterOperator.Eq, value: this.classId});

    if (this.childId) {
      decisionQuery.pushFilters({field: 'userId', operator: KendoFilterOperator.Eq, value: this.childId});
    }

    this._nushDecisionService.all({query: decisionQuery.query}).pipe(map(decisions => {
      return decisions.data.map(decision => ({
        ...decision,
        fullName: decision.fullName || '',
        shortName: getShortNameFromFullName(decision?.fullName)
      }));
    })).subscribe(decisionsWithStudentNames => {
      if (decisionsWithStudentNames.length) {
        this.students.set(decisionsWithStudentNames.naturalSort('fullName') as any);
        this.students().forEach((st, index) => st.fullName = index + 1 + '. ' + st.fullName);
      } else {
        this.students.set([]);
      }
    });
  }

  getAchievements(): void {
    const decision = this.student;

    if (!decision) return;
    const decisionQuery: Record<string, string> = {
      classId: this.classId,
      userId: decision.userId,
      domainId: this.domain.id,
      subjectId: this.subject.subjectId,
    };
    if (decision?.leaveTransferId) {
      decisionQuery.transferId = decision?.leaveTransferId;
    }

    const options: HTTPOptions = {query: decisionQuery};
    if (this.viewMode === this.nushViewModes.Parent ||
      this.viewMode === this.nushViewModes.Child) {
      options.query = {domainId: this.domain.id, subjectId: this.subject.subjectId};
      options.asUserId = this.childId;
      options.asUserSchoolId = this.schoolId;

      this._userService.getAchievementByUser(this.childId, options).subscribe(achievement => {
        this.achievements.set(this._calculateReadOnly([achievement]));
      });
    } else {
      this._achievementsService.all(options).subscribe(res => {
        this.achievements.set(this._calculateReadOnly(res.data));
      });
    }
  }

  getGeneralAchievements(): void {
    const decision = this.student;

    if (!decision) return;
    const decisionQuery = new KendoDataQuery(0, 999);
    decisionQuery.pushFilters({field: 'classId', operator: KendoFilterOperator.Eq, value: this.classId});
    decisionQuery.pushFilters({field: 'userId', operator: KendoFilterOperator.Eq, value: decision?.userId});
    decisionQuery.pushFilters({
      field: 'transferId',
      operator: decision?.leaveTransferId ? KendoFilterOperator.Eq : KendoFilterOperator.IsNull,
      value: decision?.leaveTransferId
    });

    const options: any = {query: decisionQuery.query};
    if (this.viewMode === this.nushViewModes.Parent ||
      this.viewMode === this.nushViewModes.Child) {
      options.asUserId = this.childId;
      options.asUserSchoolId = this.schoolId;
    }

    this._generalAchievementsService.all(options).subscribe(res => {
      res.data = res.data.map(achievement => {
        achievement.readOnly = !this.classIds.includes(this.classId) || this.viewMode === this.nushViewModes.Admin ||
          this.viewMode === this.nushViewModes.Parent || this.viewMode === this.nushViewModes.Child;

        return achievement;
      });

      this.generalAchievements.set(res.data);
    });
  }

  getWishes(): void {
    const decision = this.student;
    if (!decision) return;
    if (!this.hasAccessToWishes) return;
    const decisionQuery = new KendoDataQuery(0, 999);
    decisionQuery.pushFilters({field: 'classId', operator: KendoFilterOperator.Eq, value: this.classId});
    decisionQuery.pushFilters({field: 'userId', operator: KendoFilterOperator.Eq, value: decision?.userId});

    const options: any = {query: decisionQuery.query};
    if (this.viewMode === this.nushViewModes.Parent) {
      options.asUserId = this.childId;
      options.asUserSchoolId = this.schoolId;
    }

    this._achievementWishesService.all(options).subscribe(x => {
      this.wishParent.set(x.data.find(a => a.type === WishType.Parent));
      this.wishTeacher.set(x.data.find(a => a.type === WishType.ClassTeacher));
      this._checkAddRecommendation();
    });
  }

  classChanged(): void {
    this.students.set([]);
    this.getStudents();

    this.student = null;
    this.domain = null;
    this.subject = null;
    this.previousStudentId = null;
    this.achievements.set([]);
    this.generalAchievements.set([]);

    this.hasAccessToGeneralAchievements.set(this._checkAccessToGeneralAchievements());
  }

  studentChanged(): void {
    if (this.student?.id !== this.previousStudentId) {
      this.achievements.set([]);
      this.previousStudentId = this.student.id;
      this.certificate = this.student?.rule?.certificate || null;

      if (this.viewMode === this.nushViewModes.Parent || this.viewMode === this.nushViewModes.Child) {
        this._checkParentPermissions();
      }

      if (this.subject && this.student) {
        this.getAchievements();
      }

      if (this.viewMode !== this.nushViewModes.Teacher) {
        this.getGeneralAchievements();
      }

      if (!this.childId && this.viewMode !== this.nushViewModes.Teacher ||
        this.viewMode === this.nushViewModes.ClassTeacher || this.viewMode === this.nushViewModes.Parent) {
        this.getWishes();
      }
    }
  }

  domainChanged(): void {
    this.subject = null;
  }

  subjectChanged(): void {
    this.learningOutcomesSimple = this.subject.learningOutcomes.filter(x => !x.isImmutable);
    this.learningOutcomesMain = this.subject.learningOutcomes.filter(x => x.isImmutable);
    this.getAchievements();
  }

  achievementReportRequest(fileType: FileType): void {
    const studentClassName = this.classDropdown?.classes ? `, ${this.classDropdown?.classes?.find(x => x.id === this.classId)?.name}` : `, ${this.className}`;
    const fullName = this.viewMode === this.nushViewModes.Child ? this._userService.currentUser$.value.fullName
      : this.student?.fullName ? this.student?.fullName?.split('.').pop() : this.currentChild.fullName;

    const description = `${fullName}${studentClassName}`;

    let user;
    if (this.viewMode === this.nushViewModes.Parent) {
      user = {
        schoolId: this.schoolId,
        id: this.currentChild.id
      };
    }

    this._reportService.requestAchievement({
      decisionId: this.student.id,
      description,
      userId: this.student.userId
    }, fileType, user).subscribe(() => {
      this._nitToastr.info('Завантажений файл буде доступний у розділі “Завантаження”');
    });
  }

  private _checkAccessToGeneralAchievements(): boolean {
    return (this.viewMode !== this.nushViewModes.Teacher && (this._permissionsService.hasPermission(['read:general-achievement:all'])
      || (this._permissionsService.hasPermission(['read:general-achievement'])
      && this.classIds.includes(this.classId)))) || this.viewMode === this.nushViewModes.Parent || this.viewMode === this.nushViewModes.Child;
  }

  private _calculateReadOnly(achievements: Achievement[]): Achievement[] {
    return achievements?.map(achievement => {
      achievement.readOnly = !this.classDropdown?.canAccessAchievement(this.subject.name) ||
        this.viewMode === this.nushViewModes.Admin || this.viewMode === this.nushViewModes.ClassTeacher ||
        this.viewMode === this.nushViewModes.Child || this.viewMode === this.nushViewModes.Parent;

      return achievement;
    });
  }

  private _checkParentPermissions(): void {
    const decisionQuery = new KendoDataQuery(0, 999);
    decisionQuery.pushFilters({field: 'classId', operator: KendoFilterOperator.Eq, value: this.classId});
    decisionQuery.pushFilters({field: 'userId', operator: KendoFilterOperator.Eq, value: this.student?.userId});

    this._scoreCardsService.getScoreCards(decisionQuery.query, this.student.userId, this.schoolId).subscribe(res => {
      this.hasParentAccess.set(res.data[0].parentsHaveAccess);
      this.didParentView = res.data[0].parentsAreAquinted;
      this.loading$.next(false);
    });
  }

  private _getCurrentSchoolInfo(): void {
    this._userService.currentSchoolInfo$.subscribe(schoolInfo => {
      this.currentSchoolInfo = schoolInfo;

      this.currentSchoolInfo.classes.forEach(currentClass => {
        if (currentClass.hasNushRule) {
          this.classIds.push(currentClass.id);
        }
      });

      if (this.viewMode === this.nushViewModes.Child) {
        this.classId = schoolInfo?.classId;
        this.className = schoolInfo?.className;
        this.title = 'Свідоцтво досягнень ' +
          (this.viewMode === this.nushViewModes.Child ? '' : this.classDropdown?.className);
        this._getNushDecision();
      }
    });
  }

  private _initSchoolId(): void {
    this.schoolId = this.childId === this._authService.userId
      ? this._authService.getUserSchoolId()
      : this._authService.getUserSchoolId(this.childId);
  }

  private _getChildSchoolInfo(): void {
    if (this.childId) {
      this.child$?.subscribe(child => {
        this.currentChild = child;
        this.title = `Свідоцтво досягнень ${child.shortName}`;
      });
    }

    this._userService.getSchoolInfo(this.childId, this.schoolId).subscribe(schoolInfo => {
      this.currentSchoolInfo = schoolInfo;
      this.classId = schoolInfo?.classId;
      this.className = schoolInfo?.className;
      this.hasAccessToGeneralAchievements.set(this._checkAccessToGeneralAchievements());
      this._checkAddRecommendation();
      this._getNushDecision();
    });
  }

  private _getNushDecision(): void {
    const childId = (this.viewMode === this.nushViewModes.Parent ||
      this.viewMode === this.nushViewModes.Child) ? this.childId : null;

    this._userService.getNushDecision(this.childId, childId, this.schoolId).subscribe(decision => {
      this.student = decision;
      this.studentChanged();
    });
  }

  private _getChildShortName(): void {
    if (this.viewMode === this.nushViewModes.Parent) {
      this.child$ = this._userService.getRelations(this._authService.userId, 1)
        .pipe(map(response => response.data.find(child => child.id === this.childId)));
    }
  }

  private _checkAddRecommendation(): boolean {
    return this.canAddRecommendation = this.classId && this.student &&
      this.viewMode === this.nushViewModes.ClassTeacher && !this.wishTeacher;
  }
}
