/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
var setData = require('@progress/kendo-angular-intl').setData;
setData({
  name: "uk",
  identity: {
    language: "uk"
  },
  territory: "UA",
  numbers: {
    symbols: {
      decimal: ",",
      group: " ",
      list: ";",
      percentSign: "%",
      plusSign: "+",
      minusSign: "-",
      approximatelySign: "~",
      exponential: "Е",
      superscriptingExponent: "×",
      perMille: "‰",
      infinity: "∞",
      nan: "NaN",
      timeSeparator: ":"
    },
    decimal: {
      patterns: ["n"],
      groupSize: [3]
    },
    scientific: {
      patterns: ["nEn"],
      groupSize: []
    },
    percent: {
      patterns: ["n%"],
      groupSize: [3]
    },
    currency: {
      patterns: ["n $"],
      groupSize: [3],
      "unitPattern-count-one": "n $",
      "unitPattern-count-few": "n $",
      "unitPattern-count-many": "n $",
      "unitPattern-count-other": "n $"
    },
    accounting: {
      patterns: ["n $"],
      groupSize: [3]
    },
    currencies: {
      ADP: {
        displayName: "андоррська песета",
        "displayName-count-one": "андоррська песета",
        "displayName-count-few": "андоррські песети",
        "displayName-count-many": "андоррських песет",
        "displayName-count-other": "андоррських песет",
        symbol: "ADP"
      },
      AED: {
        displayName: "дирхам ОАЕ",
        "displayName-count-one": "дирхам ОАЕ",
        "displayName-count-few": "дирхами ОАЕ",
        "displayName-count-many": "дирхамів ОАЕ",
        "displayName-count-other": "дирхама ОАЕ",
        symbol: "AED"
      },
      AFA: {
        displayName: "афгані (1927–2002)",
        symbol: "AFA"
      },
      AFN: {
        displayName: "афганський афгані",
        "displayName-count-one": "афганський афгані",
        "displayName-count-few": "афганські афгані",
        "displayName-count-many": "афганських афгані",
        "displayName-count-other": "афганського афгані",
        symbol: "AFN",
        "symbol-alt-narrow": "؋"
      },
      ALK: {
        displayName: "ALK",
        symbol: "ALK"
      },
      ALL: {
        displayName: "албанський лек",
        "displayName-count-one": "албанський лек",
        "displayName-count-few": "албанські леки",
        "displayName-count-many": "албанських леків",
        "displayName-count-other": "албанського лека",
        symbol: "ALL"
      },
      AMD: {
        displayName: "вірменський драм",
        "displayName-count-one": "вірменський драм",
        "displayName-count-few": "вірменські драми",
        "displayName-count-many": "вірменських драмів",
        "displayName-count-other": "вірменського драма",
        symbol: "AMD",
        "symbol-alt-narrow": "֏"
      },
      ANG: {
        displayName: "нідерландський антильський гульден",
        "displayName-count-one": "нідерландський антильський гульден",
        "displayName-count-few": "нідерландські антильські гульдени",
        "displayName-count-many": "нідерландських антильських гульденів",
        "displayName-count-other": "нідерландського антильського гульдена",
        symbol: "ANG"
      },
      AOA: {
        displayName: "ангольська кванза",
        "displayName-count-one": "ангольська кванза",
        "displayName-count-few": "ангольські кванзи",
        "displayName-count-many": "ангольських кванз",
        "displayName-count-other": "ангольської кванзи",
        symbol: "AOA",
        "symbol-alt-narrow": "Kz"
      },
      AOK: {
        displayName: "ангольська кванза (1977–1990)",
        "displayName-count-one": "ангольська кванза (1977–1990)",
        "displayName-count-few": "ангольські кванзи (1977–1990)",
        "displayName-count-many": "ангольських кванз (1977–1990)",
        "displayName-count-other": "ангольської кванзи (1977–1990)",
        symbol: "AOK"
      },
      AON: {
        displayName: "ангольська нова кванза (1990–2000)",
        "displayName-count-one": "ангольська нова кванза (1990–2000)",
        "displayName-count-few": "ангольські нові кванзи (1990–2000)",
        "displayName-count-many": "ангольських нових кванз (1990–2000)",
        "displayName-count-other": "ангольської нової кванзи (1990–2000)",
        symbol: "AON"
      },
      AOR: {
        displayName: "ангольська кванза реаджастадо (1995–1999)",
        "displayName-count-one": "ангольська кванза реаджастадо (1995–1999)",
        "displayName-count-few": "ангольські кванзи реаджастадо (1995–1999)",
        "displayName-count-many": "ангольських кванз реаджастадо (1995–1999)",
        "displayName-count-other": "ангольської кванзи реаджастадо (1995–1999)",
        symbol: "AOR"
      },
      ARA: {
        displayName: "аргентинський австрал",
        "displayName-count-one": "аргентинський австрал",
        "displayName-count-few": "аргентинські австрали",
        "displayName-count-many": "аргентинських австралів",
        "displayName-count-other": "аргентинського австрала",
        symbol: "ARA"
      },
      ARL: {
        displayName: "ARL",
        symbol: "ARL"
      },
      ARM: {
        displayName: "ARM",
        symbol: "ARM"
      },
      ARP: {
        displayName: "аргентинський песо (1983–1985)",
        symbol: "ARP"
      },
      ARS: {
        displayName: "аргентинський песо",
        "displayName-count-one": "аргентинський песо",
        "displayName-count-few": "аргентинські песо",
        "displayName-count-many": "аргентинських песо",
        "displayName-count-other": "аргентинського песо",
        symbol: "ARS",
        "symbol-alt-narrow": "$"
      },
      ATS: {
        displayName: "австрійський шилінг",
        symbol: "ATS"
      },
      AUD: {
        displayName: "австралійський долар",
        "displayName-count-one": "австралійський долар",
        "displayName-count-few": "австралійські долари",
        "displayName-count-many": "австралійських доларів",
        "displayName-count-other": "австралійського долара",
        symbol: "AUD",
        "symbol-alt-narrow": "$"
      },
      AWG: {
        displayName: "арубський флорин",
        "displayName-count-one": "арубський флорин",
        "displayName-count-few": "арубські флорини",
        "displayName-count-many": "арубських флоринів",
        "displayName-count-other": "арубського флорина",
        symbol: "AWG"
      },
      AZM: {
        displayName: "азербайджанський манат (1993–2006)",
        "displayName-count-one": "азербайджанський манат (1993–2006)",
        "displayName-count-few": "азербайджанські манати (1993–2006)",
        "displayName-count-many": "азербайджанських манатів (1993–2006)",
        "displayName-count-other": "азербайджанського маната (1993–2006)",
        symbol: "AZM"
      },
      AZN: {
        displayName: "азербайджанський манат",
        "displayName-count-one": "азербайджанський манат",
        "displayName-count-few": "азербайджанські манати",
        "displayName-count-many": "азербайджанських манатів",
        "displayName-count-other": "азербайджанського маната",
        symbol: "AZN",
        "symbol-alt-narrow": "₼"
      },
      BAD: {
        displayName: "динар (Боснія і Герцеговина)",
        symbol: "BAD"
      },
      BAM: {
        displayName: "конвертована марка Боснії і Герцеговини",
        "displayName-count-one": "конвертована марка Боснії і Герцеговини",
        "displayName-count-few": "конвертовані марки Боснії і Герцеговини",
        "displayName-count-many": "конвертованих марок Боснії і Герцеговини",
        "displayName-count-other": "конвертованої марки Боснії і Герцеговини",
        symbol: "BAM",
        "symbol-alt-narrow": "KM"
      },
      BAN: {
        displayName: "BAN",
        symbol: "BAN"
      },
      BBD: {
        displayName: "барбадоський долар",
        "displayName-count-one": "барбадоський долар",
        "displayName-count-few": "барбадоські долари",
        "displayName-count-many": "барбадоських доларів",
        "displayName-count-other": "барбадоського долара",
        symbol: "BBD",
        "symbol-alt-narrow": "$"
      },
      BDT: {
        displayName: "бангладеська така",
        "displayName-count-one": "бангладеська така",
        "displayName-count-few": "бангладеські таки",
        "displayName-count-many": "бангладеських так",
        "displayName-count-other": "бангладеської таки",
        symbol: "BDT",
        "symbol-alt-narrow": "৳"
      },
      BEC: {
        displayName: "бельгійський франк (конвертований)",
        symbol: "BEC"
      },
      BEF: {
        displayName: "бельгійський франк",
        symbol: "BEF"
      },
      BEL: {
        displayName: "бельгійський франк (фінансовий)",
        symbol: "BEL"
      },
      BGL: {
        displayName: "болгарський твердий лев",
        symbol: "BGL"
      },
      BGM: {
        displayName: "BGM",
        symbol: "BGM"
      },
      BGN: {
        displayName: "болгарський лев",
        "displayName-count-one": "болгарський лев",
        "displayName-count-few": "болгарські леви",
        "displayName-count-many": "болгарських левів",
        "displayName-count-other": "болгарського лева",
        symbol: "BGN"
      },
      BGO: {
        displayName: "BGO",
        symbol: "BGO"
      },
      BHD: {
        displayName: "бахрейнський динар",
        "displayName-count-one": "бахрейнський динар",
        "displayName-count-few": "бахрейнські динари",
        "displayName-count-many": "бахрейнських динарів",
        "displayName-count-other": "бахрейнського динара",
        symbol: "BHD"
      },
      BIF: {
        displayName: "бурундійський франк",
        "displayName-count-one": "бурундійський франк",
        "displayName-count-few": "бурундійські франки",
        "displayName-count-many": "бурундійських франків",
        "displayName-count-other": "бурундійського франка",
        symbol: "BIF"
      },
      BMD: {
        displayName: "бермудський долар",
        "displayName-count-one": "бермудський долар",
        "displayName-count-few": "бермудські долари",
        "displayName-count-many": "бермудських доларів",
        "displayName-count-other": "бермудського долара",
        symbol: "BMD",
        "symbol-alt-narrow": "$"
      },
      BND: {
        displayName: "брунейський долар",
        "displayName-count-one": "брунейський долар",
        "displayName-count-few": "брунейські долари",
        "displayName-count-many": "брунейських доларів",
        "displayName-count-other": "брунейського долара",
        symbol: "BND",
        "symbol-alt-narrow": "$"
      },
      BOB: {
        displayName: "болівійський болівіано",
        "displayName-count-one": "болівійський болівіано",
        "displayName-count-few": "болівійські болівіано",
        "displayName-count-many": "болівійських болівіано",
        "displayName-count-other": "болівійського болівіано",
        symbol: "BOB",
        "symbol-alt-narrow": "Bs"
      },
      BOL: {
        displayName: "BOL",
        symbol: "BOL"
      },
      BOP: {
        displayName: "болівійське песо",
        symbol: "BOP"
      },
      BOV: {
        displayName: "болівійський мвдол",
        symbol: "BOV"
      },
      BRB: {
        displayName: "бразильське нове крузейро (1967–1986)",
        symbol: "BRB"
      },
      BRC: {
        displayName: "бразильське крузадо",
        symbol: "BRC"
      },
      BRE: {
        displayName: "бразильське крузейро (1990–1993)",
        symbol: "BRE"
      },
      BRL: {
        displayName: "бразильський реал",
        "displayName-count-one": "бразильський реал",
        "displayName-count-few": "бразильські реали",
        "displayName-count-many": "бразильських реалів",
        "displayName-count-other": "бразильського реала",
        symbol: "BRL",
        "symbol-alt-narrow": "R$"
      },
      BRN: {
        displayName: "бразильське нове крузадо",
        symbol: "BRN"
      },
      BRR: {
        displayName: "бразильське крузейро",
        symbol: "BRR"
      },
      BRZ: {
        displayName: "BRZ",
        symbol: "BRZ"
      },
      BSD: {
        displayName: "багамський долар",
        "displayName-count-one": "багамський долар",
        "displayName-count-few": "багамські долари",
        "displayName-count-many": "багамських доларів",
        "displayName-count-other": "багамського долара",
        symbol: "BSD",
        "symbol-alt-narrow": "$"
      },
      BTN: {
        displayName: "бутанський нгултрум",
        "displayName-count-one": "бутанський нгултрум",
        "displayName-count-few": "бутанські нгултруми",
        "displayName-count-many": "бутанських нгултрумів",
        "displayName-count-other": "бутанського нгултрума",
        symbol: "BTN"
      },
      BUK: {
        displayName: "бірманський кіат",
        symbol: "BUK"
      },
      BWP: {
        displayName: "ботсванська пула",
        "displayName-count-one": "ботсванська пула",
        "displayName-count-few": "ботсванські пули",
        "displayName-count-many": "ботсванських пул",
        "displayName-count-other": "ботсванської пули",
        symbol: "BWP",
        "symbol-alt-narrow": "P"
      },
      BYB: {
        displayName: "білоруський новий рубль (1994–1999)",
        "displayName-count-one": "білоруський новий рубль (1994–1999)",
        "displayName-count-few": "білоруські нові рублі (1994–1999)",
        "displayName-count-many": "білоруських нових рублів (1994–1999)",
        "displayName-count-other": "білоруського нового рубля (1994–1999)",
        symbol: "BYB"
      },
      BYN: {
        displayName: "білоруський рубль",
        "displayName-count-one": "білоруський рубль",
        "displayName-count-few": "білоруські рублі",
        "displayName-count-many": "білоруських рублів",
        "displayName-count-other": "білоруського рубля",
        symbol: "BYN",
        "symbol-alt-narrow": "р."
      },
      BYR: {
        displayName: "білоруський рубль (2000–2016)",
        "displayName-count-one": "білоруський рубль (2000–2016)",
        "displayName-count-few": "білоруські рублі (2000–2016)",
        "displayName-count-many": "білоруських рублів (2000–2016)",
        "displayName-count-other": "білоруського рубля (2000–2016)",
        symbol: "BYR"
      },
      BZD: {
        displayName: "белізький долар",
        "displayName-count-one": "белізький долар",
        "displayName-count-few": "белізькі долари",
        "displayName-count-many": "белізьких доларів",
        "displayName-count-other": "белізького долара",
        symbol: "BZD",
        "symbol-alt-narrow": "$"
      },
      CAD: {
        displayName: "канадський долар",
        "displayName-count-one": "канадський долар",
        "displayName-count-few": "канадські долари",
        "displayName-count-many": "канадських доларів",
        "displayName-count-other": "канадського долара",
        symbol: "CAD",
        "symbol-alt-narrow": "$"
      },
      CDF: {
        displayName: "конголезький франк",
        "displayName-count-one": "конголезький франк",
        "displayName-count-few": "конголезькі франки",
        "displayName-count-many": "конголезьких франків",
        "displayName-count-other": "конголезького франка",
        symbol: "CDF"
      },
      CHE: {
        displayName: "євро WIR",
        symbol: "CHE"
      },
      CHF: {
        displayName: "швейцарський франк",
        "displayName-count-one": "швейцарський франк",
        "displayName-count-few": "швейцарські франки",
        "displayName-count-many": "швейцарських франків",
        "displayName-count-other": "швейцарського франка",
        symbol: "CHF"
      },
      CHW: {
        displayName: "франк WIR",
        symbol: "CHW"
      },
      CLE: {
        displayName: "CLE",
        symbol: "CLE"
      },
      CLF: {
        displayName: "чилійський юнідадес де фоменто",
        symbol: "CLF"
      },
      CLP: {
        displayName: "чилійський песо",
        "displayName-count-one": "чилійський песо",
        "displayName-count-few": "чилійські песо",
        "displayName-count-many": "чилійських песо",
        "displayName-count-other": "чилійського песо",
        symbol: "CLP",
        "symbol-alt-narrow": "$"
      },
      CNH: {
        displayName: "китайський офшорний юань",
        "displayName-count-one": "китайський офшорний юань",
        "displayName-count-few": "китайські офшорні юані",
        "displayName-count-many": "китайських офшорних юанів",
        "displayName-count-other": "китайського офшорного юаня",
        symbol: "CNH"
      },
      CNX: {
        displayName: "CNX",
        symbol: "CNX"
      },
      CNY: {
        displayName: "китайський юань",
        "displayName-count-one": "китайський юань",
        "displayName-count-few": "китайські юані",
        "displayName-count-many": "китайських юанів",
        "displayName-count-other": "китайського юаня",
        symbol: "CNY",
        "symbol-alt-narrow": "¥"
      },
      COP: {
        displayName: "колумбійський песо",
        "displayName-count-one": "колумбійський песо",
        "displayName-count-few": "колумбійські песо",
        "displayName-count-many": "колумбійських песо",
        "displayName-count-other": "колумбійського песо",
        symbol: "COP",
        "symbol-alt-narrow": "$"
      },
      COU: {
        displayName: "одиниця реальної вартості",
        symbol: "COU"
      },
      CRC: {
        displayName: "костариканський колон",
        "displayName-count-one": "костариканський колон",
        "displayName-count-few": "костариканські колони",
        "displayName-count-many": "костариканських колонів",
        "displayName-count-other": "костариканського колона",
        symbol: "CRC",
        "symbol-alt-narrow": "₡"
      },
      CSD: {
        displayName: "старий сербський динар",
        symbol: "CSD"
      },
      CSK: {
        displayName: "чехословацька тверда крона",
        symbol: "CSK"
      },
      CUC: {
        displayName: "кубинський конвертований песо",
        "displayName-count-one": "кубинський конвертований песо",
        "displayName-count-few": "кубинські конвертовані песо",
        "displayName-count-many": "кубинських конвертованих песо",
        "displayName-count-other": "кубинського конвертованого песо",
        symbol: "CUC",
        "symbol-alt-narrow": "$"
      },
      CUP: {
        displayName: "кубинський песо",
        "displayName-count-one": "кубинський песо",
        "displayName-count-few": "кубинські песо",
        "displayName-count-many": "кубинських песо",
        "displayName-count-other": "кубинського песо",
        symbol: "CUP",
        "symbol-alt-narrow": "$"
      },
      CVE: {
        displayName: "ескудо Кабо-Верде",
        "displayName-count-one": "ескудо Кабо-Верде",
        "displayName-count-few": "ескудо Кабо-Верде",
        "displayName-count-many": "ескудо Кабо-Верде",
        "displayName-count-other": "ескудо Кабо-Верде",
        symbol: "CVE"
      },
      CYP: {
        displayName: "кіпрський фунт",
        symbol: "CYP"
      },
      CZK: {
        displayName: "чеська крона",
        "displayName-count-one": "чеська крона",
        "displayName-count-few": "чеські крони",
        "displayName-count-many": "чеських крон",
        "displayName-count-other": "чеської крони",
        symbol: "CZK",
        "symbol-alt-narrow": "Kč"
      },
      DDM: {
        displayName: "марка НДР",
        symbol: "DDM"
      },
      DEM: {
        displayName: "німецька марка",
        symbol: "DEM"
      },
      DJF: {
        displayName: "джибутійський франк",
        "displayName-count-one": "джибутійський франк",
        "displayName-count-few": "джибутійські франки",
        "displayName-count-many": "джибутійських франків",
        "displayName-count-other": "джибутійського франка",
        symbol: "DJF"
      },
      DKK: {
        displayName: "данська крона",
        "displayName-count-one": "данська крона",
        "displayName-count-few": "данські крони",
        "displayName-count-many": "данських крон",
        "displayName-count-other": "данської крони",
        symbol: "DKK",
        "symbol-alt-narrow": "kr"
      },
      DOP: {
        displayName: "домініканський песо",
        "displayName-count-one": "домініканський песо",
        "displayName-count-few": "домініканські песо",
        "displayName-count-many": "домініканських песо",
        "displayName-count-other": "домініканського песо",
        symbol: "DOP",
        "symbol-alt-narrow": "$"
      },
      DZD: {
        displayName: "алжирський динар",
        "displayName-count-one": "алжирський динар",
        "displayName-count-few": "алжирські динари",
        "displayName-count-many": "алжирських динарів",
        "displayName-count-other": "алжирського динара",
        symbol: "DZD"
      },
      ECS: {
        displayName: "еквадорський сукре",
        symbol: "ECS"
      },
      ECV: {
        displayName: "еквадорський юнідад де валор константе",
        symbol: "ECV"
      },
      EEK: {
        displayName: "естонська крона",
        "displayName-count-one": "естонська крона",
        "displayName-count-few": "естонські крони",
        "displayName-count-many": "естонських крон",
        "displayName-count-other": "естонської крони",
        symbol: "EEK"
      },
      EGP: {
        displayName: "єгипетський фунт",
        "displayName-count-one": "єгипетський фунт",
        "displayName-count-few": "єгипетські фунти",
        "displayName-count-many": "єгипетських фунтів",
        "displayName-count-other": "єгипетського фунта",
        symbol: "EGP",
        "symbol-alt-narrow": "E£"
      },
      ERN: {
        displayName: "еритрейська накфа",
        "displayName-count-one": "еритрейська накфа",
        "displayName-count-few": "еритрейські накфи",
        "displayName-count-many": "еритрейських накф",
        "displayName-count-other": "еритрейської накфи",
        symbol: "ERN"
      },
      ESA: {
        displayName: "іспанська песета (\\\"А\\\" рахунок)",
        symbol: "ESA"
      },
      ESB: {
        displayName: "іспанська песета (конвертовані рахунки)",
        symbol: "ESB"
      },
      ESP: {
        displayName: "іспанська песета",
        symbol: "ESP",
        "symbol-alt-narrow": "₧"
      },
      ETB: {
        displayName: "ефіопський бир",
        "displayName-count-one": "ефіопський бир",
        "displayName-count-few": "ефіопські бири",
        "displayName-count-many": "ефіопських бирів",
        "displayName-count-other": "ефіопського бира",
        symbol: "ETB"
      },
      EUR: {
        displayName: "євро",
        "displayName-count-one": "євро",
        "displayName-count-few": "євро",
        "displayName-count-many": "євро",
        "displayName-count-other": "євро",
        symbol: "EUR",
        "symbol-alt-narrow": "€"
      },
      FIM: {
        displayName: "фінляндська марка",
        symbol: "FIM"
      },
      FJD: {
        displayName: "фіджійський долар",
        "displayName-count-one": "фіджійський долар",
        "displayName-count-few": "фіджійські долари",
        "displayName-count-many": "фіджійських доларів",
        "displayName-count-other": "фіджійського долара",
        symbol: "FJD",
        "symbol-alt-narrow": "$"
      },
      FKP: {
        displayName: "фунт Фолклендських островів",
        "displayName-count-one": "фунт Фолклендських островів",
        "displayName-count-few": "фунти Фолклендських островів",
        "displayName-count-many": "фунтів Фолклендських островів",
        "displayName-count-other": "фунта Фолклендських островів",
        symbol: "FKP",
        "symbol-alt-narrow": "£"
      },
      FRF: {
        displayName: "французький франк",
        symbol: "FRF"
      },
      GBP: {
        displayName: "англійський фунт",
        "displayName-count-one": "англійський фунт",
        "displayName-count-few": "англійські фунти",
        "displayName-count-many": "англійських фунтів",
        "displayName-count-other": "англійського фунта",
        symbol: "GBP",
        "symbol-alt-narrow": "£"
      },
      GEK: {
        displayName: "грузинський купон",
        "displayName-count-one": "грузинський купон",
        "displayName-count-few": "грузинські купони",
        "displayName-count-many": "грузинських купонів",
        "displayName-count-other": "грузинського купона",
        symbol: "GEK"
      },
      GEL: {
        displayName: "грузинський ларі",
        "displayName-count-one": "грузинський ларі",
        "displayName-count-few": "грузинські ларі",
        "displayName-count-many": "грузинських ларі",
        "displayName-count-other": "грузинського ларі",
        symbol: "GEL",
        "symbol-alt-narrow": "₾"
      },
      GHC: {
        displayName: "ганський седі (1979–2007)",
        symbol: "GHC"
      },
      GHS: {
        displayName: "ганський седі",
        "displayName-count-one": "ганський седі",
        "displayName-count-few": "ганські седі",
        "displayName-count-many": "ганських седі",
        "displayName-count-other": "ганського седі",
        symbol: "GHS",
        "symbol-alt-narrow": "GH₵"
      },
      GIP: {
        displayName: "гібралтарський фунт",
        "displayName-count-one": "гібралтарський фунт",
        "displayName-count-few": "гібралтарські фунти",
        "displayName-count-many": "гібралтарських фунтів",
        "displayName-count-other": "гібралтарського фунта",
        symbol: "GIP",
        "symbol-alt-narrow": "£"
      },
      GMD: {
        displayName: "гамбійський даласі",
        "displayName-count-one": "гамбійський даласі",
        "displayName-count-few": "гамбійські даласі",
        "displayName-count-many": "гамбійських даласі",
        "displayName-count-other": "гамбійського даласі",
        symbol: "GMD"
      },
      GNF: {
        displayName: "гвінейський франк",
        "displayName-count-one": "гвінейський франк",
        "displayName-count-few": "гвінейські франки",
        "displayName-count-many": "гвінейських франків",
        "displayName-count-other": "гвінейського франка",
        symbol: "GNF",
        "symbol-alt-narrow": "FG"
      },
      GNS: {
        displayName: "гвінейське сілі",
        symbol: "GNS"
      },
      GQE: {
        displayName: "еквеле (Екваторіальна Ґвінея)",
        symbol: "GQE"
      },
      GRD: {
        displayName: "грецька драхма",
        symbol: "GRD"
      },
      GTQ: {
        displayName: "гватемальський кетсаль",
        "displayName-count-one": "гватемальський кетсаль",
        "displayName-count-few": "гватемальські кетсалі",
        "displayName-count-many": "гватемальських кетсалів",
        "displayName-count-other": "гватемальського кетсаля",
        symbol: "GTQ",
        "symbol-alt-narrow": "Q"
      },
      GWE: {
        displayName: "ескудо португальської гвінеї",
        symbol: "GWE"
      },
      GWP: {
        displayName: "песо Гвінеї-Бісау",
        symbol: "GWP"
      },
      GYD: {
        displayName: "гаянський долар",
        "displayName-count-one": "гаянський долар",
        "displayName-count-few": "гаянські долари",
        "displayName-count-many": "гаянських доларів",
        "displayName-count-other": "гаянського долара",
        symbol: "GYD",
        "symbol-alt-narrow": "$"
      },
      HKD: {
        displayName: "гонконгський долар",
        "displayName-count-one": "гонконгський долар",
        "displayName-count-few": "гонконгські долари",
        "displayName-count-many": "гонконгських доларів",
        "displayName-count-other": "гонконгського долара",
        symbol: "HKD",
        "symbol-alt-narrow": "$"
      },
      HNL: {
        displayName: "гондураська лемпіра",
        "displayName-count-one": "гондураська лемпіра",
        "displayName-count-few": "гондураські лемпіри",
        "displayName-count-many": "гондураських лемпір",
        "displayName-count-other": "гондураської лемпіри",
        symbol: "HNL",
        "symbol-alt-narrow": "L"
      },
      HRD: {
        displayName: "хорватський динар",
        symbol: "HRD"
      },
      HRK: {
        displayName: "хорватська куна",
        "displayName-count-one": "хорватська куна",
        "displayName-count-few": "хорватські куни",
        "displayName-count-many": "хорватських кун",
        "displayName-count-other": "хорватської куни",
        symbol: "HRK",
        "symbol-alt-narrow": "kn"
      },
      HTG: {
        displayName: "гаїтянський гурд",
        "displayName-count-one": "гаїтянський гурд",
        "displayName-count-few": "гаїтянські гурди",
        "displayName-count-many": "гаїтянських гурдів",
        "displayName-count-other": "гаїтянського гурда",
        symbol: "HTG"
      },
      HUF: {
        displayName: "угорський форинт",
        "displayName-count-one": "угорський форинт",
        "displayName-count-few": "угорські форинти",
        "displayName-count-many": "угорських форинтів",
        "displayName-count-other": "угорського форинта",
        symbol: "HUF",
        "symbol-alt-narrow": "Ft"
      },
      IDR: {
        displayName: "індонезійська рупія",
        "displayName-count-one": "індонезійська рупія",
        "displayName-count-few": "індонезійські рупії",
        "displayName-count-many": "індонезійських рупій",
        "displayName-count-other": "індонезійські рупії",
        symbol: "IDR",
        "symbol-alt-narrow": "Rp"
      },
      IEP: {
        displayName: "ірландський фунт",
        symbol: "IEP"
      },
      ILP: {
        displayName: "ізраїльський фунт",
        symbol: "ILP"
      },
      ILR: {
        displayName: "ILR",
        symbol: "ILR"
      },
      ILS: {
        displayName: "ізраїльський новий шекель",
        "displayName-count-one": "ізраїльський новий шекель",
        "displayName-count-few": "ізраїльські нові шекелі",
        "displayName-count-many": "ізраїльських нових шекелів",
        "displayName-count-other": "ізраїльського нового шекеля",
        symbol: "ILS",
        "symbol-alt-narrow": "₪"
      },
      INR: {
        displayName: "індійська рупія",
        "displayName-count-one": "індійська рупія",
        "displayName-count-few": "індійські рупії",
        "displayName-count-many": "індійських рупій",
        "displayName-count-other": "індійської рупії",
        symbol: "INR",
        "symbol-alt-narrow": "₹"
      },
      IQD: {
        displayName: "іракський динар",
        "displayName-count-one": "іракський динар",
        "displayName-count-few": "іракські динари",
        "displayName-count-many": "іракських динарів",
        "displayName-count-other": "іракського динара",
        symbol: "IQD"
      },
      IRR: {
        displayName: "іранський ріал",
        "displayName-count-one": "іранський ріал",
        "displayName-count-few": "іранські ріали",
        "displayName-count-many": "іранських ріалів",
        "displayName-count-other": "іранського ріала",
        symbol: "IRR"
      },
      ISJ: {
        displayName: "ISJ",
        symbol: "ISJ"
      },
      ISK: {
        displayName: "ісландська крона",
        "displayName-count-one": "ісландська крона",
        "displayName-count-few": "ісландські кроні",
        "displayName-count-many": "ісландських крон",
        "displayName-count-other": "ісландської крони",
        symbol: "ISK",
        "symbol-alt-narrow": "kr"
      },
      ITL: {
        displayName: "італійська ліра",
        symbol: "ITL"
      },
      JMD: {
        displayName: "ямайський долар",
        "displayName-count-one": "ямайський долар",
        "displayName-count-few": "ямайські долари",
        "displayName-count-many": "ямайських доларів",
        "displayName-count-other": "ямайського долара",
        symbol: "JMD",
        "symbol-alt-narrow": "$"
      },
      JOD: {
        displayName: "йорданський динар",
        "displayName-count-one": "йорданський динар",
        "displayName-count-few": "йорданські динари",
        "displayName-count-many": "йорданських динарів",
        "displayName-count-other": "йорданського динара",
        symbol: "JOD"
      },
      JPY: {
        displayName: "японська єна",
        "displayName-count-one": "японська єна",
        "displayName-count-few": "японські єни",
        "displayName-count-many": "японських єн",
        "displayName-count-other": "японської єни",
        symbol: "¥",
        "symbol-alt-narrow": "¥"
      },
      KES: {
        displayName: "кенійський шилінг",
        "displayName-count-one": "кенійський шилінг",
        "displayName-count-few": "кенійські шилінги",
        "displayName-count-many": "кенійських шилінгів",
        "displayName-count-other": "кенійського шилінга",
        symbol: "KES"
      },
      KGS: {
        displayName: "киргизький сом",
        "displayName-count-one": "киргизький сом",
        "displayName-count-few": "киргизькі соми",
        "displayName-count-many": "киргизьких сомів",
        "displayName-count-other": "киргизького сома",
        symbol: "KGS"
      },
      KHR: {
        displayName: "камбоджійський рієль",
        "displayName-count-one": "камбоджійський рієль",
        "displayName-count-few": "камбоджійські рієлі",
        "displayName-count-many": "камбоджійських рієлів",
        "displayName-count-other": "камбоджійського рієля",
        symbol: "KHR",
        "symbol-alt-narrow": "៛"
      },
      KMF: {
        displayName: "коморський франк",
        "displayName-count-one": "коморський франк",
        "displayName-count-few": "коморські франки",
        "displayName-count-many": "коморських франків",
        "displayName-count-other": "коморського франка",
        symbol: "KMF",
        "symbol-alt-narrow": "CF"
      },
      KPW: {
        displayName: "північнокорейський вон",
        "displayName-count-one": "північнокорейський вон",
        "displayName-count-few": "північнокорейські вони",
        "displayName-count-many": "північнокорейських вонів",
        "displayName-count-other": "північнокорейського вона",
        symbol: "KPW",
        "symbol-alt-narrow": "₩"
      },
      KRH: {
        displayName: "KRH",
        symbol: "KRH"
      },
      KRO: {
        displayName: "KRO",
        symbol: "KRO"
      },
      KRW: {
        displayName: "південнокорейський вон",
        "displayName-count-one": "південнокорейський вон",
        "displayName-count-few": "південнокорейські вони",
        "displayName-count-many": "південнокорейських вонів",
        "displayName-count-other": "південнокорейського вона",
        symbol: "KRW",
        "symbol-alt-narrow": "₩"
      },
      KWD: {
        displayName: "кувейтський динар",
        "displayName-count-one": "кувейтський динар",
        "displayName-count-few": "кувейтські динари",
        "displayName-count-many": "кувейтських динарів",
        "displayName-count-other": "кувейтського динара",
        symbol: "KWD"
      },
      KYD: {
        displayName: "долар Кайманових островів",
        "displayName-count-one": "долар Кайманових островів",
        "displayName-count-few": "долари Кайманових островів",
        "displayName-count-many": "доларів Кайманових островів",
        "displayName-count-other": "долара Кайманових островів",
        symbol: "KYD",
        "symbol-alt-narrow": "$"
      },
      KZT: {
        displayName: "казахстанський тенге",
        "displayName-count-one": "казахстанський тенге",
        "displayName-count-few": "казахстанські тенге",
        "displayName-count-many": "казахстанських тенге",
        "displayName-count-other": "казахстанського тенге",
        symbol: "KZT",
        "symbol-alt-narrow": "₸"
      },
      LAK: {
        displayName: "лаоський кіп",
        "displayName-count-one": "лаоський кіп",
        "displayName-count-few": "лаоські кіпи",
        "displayName-count-many": "лаоських кіпів",
        "displayName-count-other": "лаоського кіпа",
        symbol: "LAK",
        "symbol-alt-narrow": "₭"
      },
      LBP: {
        displayName: "ліванський фунт",
        "displayName-count-one": "ліванський фунт",
        "displayName-count-few": "ліванські фунти",
        "displayName-count-many": "ліванських фунтів",
        "displayName-count-other": "ліванського фунта",
        symbol: "LBP",
        "symbol-alt-narrow": "L£"
      },
      LKR: {
        displayName: "шрі-ланкійська рупія",
        "displayName-count-one": "шрі-ланкійська рупія",
        "displayName-count-few": "шрі-ланкійські рупії",
        "displayName-count-many": "шрі-ланкійських рупій",
        "displayName-count-other": "шрі-ланкійської рупії",
        symbol: "LKR",
        "symbol-alt-narrow": "Rs"
      },
      LRD: {
        displayName: "ліберійський долар",
        "displayName-count-one": "ліберійський долар",
        "displayName-count-few": "ліберійські долари",
        "displayName-count-many": "ліберійських доларів",
        "displayName-count-other": "ліберійського долара",
        symbol: "LRD",
        "symbol-alt-narrow": "$"
      },
      LSL: {
        displayName: "лесотський лоті",
        "displayName-count-one": "лесотський лоті",
        "displayName-count-few": "лесотські лоті",
        "displayName-count-many": "лесотських лоті",
        "displayName-count-other": "лесотського лоті",
        symbol: "LSL"
      },
      LTL: {
        displayName: "литовський літ",
        "displayName-count-one": "литовський літ",
        "displayName-count-few": "литовські літи",
        "displayName-count-many": "литовських літів",
        "displayName-count-other": "литовського літа",
        symbol: "LTL",
        "symbol-alt-narrow": "Lt"
      },
      LTT: {
        displayName: "литовський талон",
        symbol: "LTT"
      },
      LUC: {
        displayName: "люксембурґський франк (конвертований)",
        symbol: "LUC"
      },
      LUF: {
        displayName: "люксембурзький франк",
        symbol: "LUF"
      },
      LUL: {
        displayName: "люксембурґський франк (фінансовий)",
        symbol: "LUL"
      },
      LVL: {
        displayName: "латвійський лат",
        "displayName-count-one": "латвійський лат",
        "displayName-count-few": "латвійські лати",
        "displayName-count-many": "латвійських латів",
        "displayName-count-other": "латвійського лата",
        symbol: "LVL",
        "symbol-alt-narrow": "Ls"
      },
      LVR: {
        displayName: "латвійський рубль",
        symbol: "LVR"
      },
      LYD: {
        displayName: "лівійський динар",
        "displayName-count-one": "лівійський динар",
        "displayName-count-few": "лівійські динари",
        "displayName-count-many": "лівійських динарів",
        "displayName-count-other": "лівійського динара",
        symbol: "LYD"
      },
      MAD: {
        displayName: "марокканський дирхам",
        "displayName-count-one": "марокканський дирхам",
        "displayName-count-few": "марокканські дирхами",
        "displayName-count-many": "марокканських дирхамів",
        "displayName-count-other": "марокканського дирхама",
        symbol: "MAD"
      },
      MAF: {
        displayName: "марокканський франк",
        symbol: "MAF"
      },
      MCF: {
        displayName: "MCF",
        symbol: "MCF"
      },
      MDC: {
        displayName: "MDC",
        symbol: "MDC"
      },
      MDL: {
        displayName: "молдовський лей",
        "displayName-count-one": "молдовський лей",
        "displayName-count-few": "молдовські леї",
        "displayName-count-many": "молдовських леїв",
        "displayName-count-other": "молдовського лея",
        symbol: "MDL"
      },
      MGA: {
        displayName: "малагасійський аріарі",
        "displayName-count-one": "малагасійський аріарі",
        "displayName-count-few": "малагасійські аріарі",
        "displayName-count-many": "малагасійських аріарі",
        "displayName-count-other": "малагасійського аріарі",
        symbol: "MGA",
        "symbol-alt-narrow": "Ar"
      },
      MGF: {
        displayName: "мадагаскарський франк",
        symbol: "MGF"
      },
      MKD: {
        displayName: "македонський денар",
        "displayName-count-one": "македонський денар",
        "displayName-count-few": "македонські денари",
        "displayName-count-many": "македонських денарів",
        "displayName-count-other": "македонського денара",
        symbol: "MKD"
      },
      MKN: {
        displayName: "MKN",
        symbol: "MKN"
      },
      MLF: {
        displayName: "малійський франк",
        symbol: "MLF"
      },
      MMK: {
        displayName: "кʼят Мʼянми",
        "displayName-count-one": "кʼят Мʼянми",
        "displayName-count-few": "кʼяти Мʼянми",
        "displayName-count-many": "кʼятів Мʼянми",
        "displayName-count-other": "кʼята Мʼянми",
        symbol: "MMK",
        "symbol-alt-narrow": "K"
      },
      MNT: {
        displayName: "монгольський тугрик",
        "displayName-count-one": "монгольський тугрик",
        "displayName-count-few": "монгольські тугрики",
        "displayName-count-many": "монгольських тугриків",
        "displayName-count-other": "монгольського тугрика",
        symbol: "MNT",
        "symbol-alt-narrow": "₮"
      },
      MOP: {
        displayName: "патака Макао",
        "displayName-count-one": "патака Макао",
        "displayName-count-few": "патаки Макао",
        "displayName-count-many": "патак Макао",
        "displayName-count-other": "патаки Макао",
        symbol: "MOP"
      },
      MRO: {
        displayName: "мавританська угія (1973–2017)",
        "displayName-count-one": "мавританська угія (1973–2017)",
        "displayName-count-few": "мавританські угії (1973–2017)",
        "displayName-count-many": "мавританських угій (1973–2017)",
        "displayName-count-other": "мавританської угії (1973–2017)",
        symbol: "MRO"
      },
      MRU: {
        displayName: "мавританська угія",
        "displayName-count-one": "мавританська угія",
        "displayName-count-few": "мавританські угії",
        "displayName-count-many": "мавританських угій",
        "displayName-count-other": "мавританської угії",
        symbol: "MRU"
      },
      MTL: {
        displayName: "мальтійська ліра",
        symbol: "MTL"
      },
      MTP: {
        displayName: "мальтійський фунт",
        symbol: "MTP"
      },
      MUR: {
        displayName: "маврикійська рупія",
        "displayName-count-one": "маврикійська рупія",
        "displayName-count-few": "маврикійські рупії",
        "displayName-count-many": "маврикійських рупій",
        "displayName-count-other": "маврикійської рупії",
        symbol: "MUR",
        "symbol-alt-narrow": "Rs"
      },
      MVP: {
        displayName: "MVP",
        symbol: "MVP"
      },
      MVR: {
        displayName: "мальдівська руфія",
        "displayName-count-one": "мальдівська руфія",
        "displayName-count-few": "мальдівські руфії",
        "displayName-count-many": "мальдівських руфій",
        "displayName-count-other": "мальдівської руфії",
        symbol: "MVR"
      },
      MWK: {
        displayName: "малавійська квача",
        "displayName-count-one": "малавійська квача",
        "displayName-count-few": "малавійські квачі",
        "displayName-count-many": "малавійських квач",
        "displayName-count-other": "малавійської квачі",
        symbol: "MWK"
      },
      MXN: {
        displayName: "мексиканський песо",
        "displayName-count-one": "мексиканський песо",
        "displayName-count-few": "мексиканські песо",
        "displayName-count-many": "мексиканських песо",
        "displayName-count-other": "мексиканського песо",
        symbol: "MXN",
        "symbol-alt-narrow": "$"
      },
      MXP: {
        displayName: "мексиканське срібне песо (1861–1992)",
        symbol: "MXP"
      },
      MXV: {
        displayName: "мексиканський юнідад де інверсіон",
        symbol: "MXV"
      },
      MYR: {
        displayName: "малайзійський рингіт",
        "displayName-count-one": "малайзійський рингіт",
        "displayName-count-few": "малайзійські рингіти",
        "displayName-count-many": "малайзійських рингітів",
        "displayName-count-other": "малайзійського рингіта",
        symbol: "MYR",
        "symbol-alt-narrow": "RM"
      },
      MZE: {
        displayName: "мозамбіцький ескудо",
        symbol: "MZE"
      },
      MZM: {
        displayName: "старий мозамбіцький метикал",
        symbol: "MZM"
      },
      MZN: {
        displayName: "мозамбіцький метикал",
        "displayName-count-one": "мозамбіцький метикал",
        "displayName-count-few": "мозамбіцькі метикали",
        "displayName-count-many": "мозамбіцьких метикалів",
        "displayName-count-other": "мозамбіцького метикала",
        symbol: "MZN"
      },
      NAD: {
        displayName: "намібійський долар",
        "displayName-count-one": "намібійський долар",
        "displayName-count-few": "намібійські долари",
        "displayName-count-many": "намібійських доларів",
        "displayName-count-other": "намібійського долара",
        symbol: "NAD",
        "symbol-alt-narrow": "$"
      },
      NGN: {
        displayName: "нігерійська найра",
        "displayName-count-one": "нігерійська найра",
        "displayName-count-few": "нігерійські найри",
        "displayName-count-many": "нігерійських найр",
        "displayName-count-other": "нігерійської найри",
        symbol: "NGN",
        "symbol-alt-narrow": "₦"
      },
      NIC: {
        displayName: "нікарагуанська кордоба (1988–1991)",
        "displayName-count-one": "нікарагуанська кордоба (1988–1991)",
        "displayName-count-few": "нікарагуанські кордоби (1988–1991)",
        "displayName-count-many": "нікарагуанських кордоб (1988–1991)",
        "displayName-count-other": "нікарагуанської кордоби (1988–1991)",
        symbol: "NIC"
      },
      NIO: {
        displayName: "нікарагуанська кордоба",
        "displayName-count-one": "нікарагуанська кордоба",
        "displayName-count-few": "нікарагуанські кордоби",
        "displayName-count-many": "нікарагуанських кордоб",
        "displayName-count-other": "нікарагуанської кордоби",
        symbol: "NIO",
        "symbol-alt-narrow": "C$"
      },
      NLG: {
        displayName: "нідерландський гульден",
        symbol: "NLG"
      },
      NOK: {
        displayName: "норвезька крона",
        "displayName-count-one": "норвезька крона",
        "displayName-count-few": "норвезькі крони",
        "displayName-count-many": "норвезьких крон",
        "displayName-count-other": "норвезької крони",
        symbol: "NOK",
        "symbol-alt-narrow": "kr"
      },
      NPR: {
        displayName: "непальська рупія",
        "displayName-count-one": "непальська рупія",
        "displayName-count-few": "непальські рупії",
        "displayName-count-many": "непальських рупій",
        "displayName-count-other": "непальської рупії",
        symbol: "NPR",
        "symbol-alt-narrow": "Rs"
      },
      NZD: {
        displayName: "новозеландський долар",
        "displayName-count-one": "новозеландський долар",
        "displayName-count-few": "новозеландські долари",
        "displayName-count-many": "новозеландських доларів",
        "displayName-count-other": "новозеландського долара",
        symbol: "NZD",
        "symbol-alt-narrow": "$"
      },
      OMR: {
        displayName: "оманський ріал",
        "displayName-count-one": "оманський ріал",
        "displayName-count-few": "оманські ріали",
        "displayName-count-many": "оманських ріалів",
        "displayName-count-other": "оманського ріала",
        symbol: "OMR"
      },
      PAB: {
        displayName: "панамське бальбоа",
        "displayName-count-one": "панамське бальбоа",
        "displayName-count-few": "панамські бальбоа",
        "displayName-count-many": "панамських бальбоа",
        "displayName-count-other": "панамського бальбоа",
        symbol: "PAB"
      },
      PEI: {
        displayName: "перуанський інті",
        symbol: "PEI"
      },
      PEN: {
        displayName: "перуанський новий сол",
        "displayName-count-one": "перуанський новий сол",
        "displayName-count-few": "перуанські нові соли",
        "displayName-count-many": "перуанських нових солів",
        "displayName-count-other": "перуанського нового сола",
        symbol: "PEN"
      },
      PES: {
        displayName: "перуанський сол (1863–1965)",
        "displayName-count-one": "перуанський сол (1863–1965)",
        "displayName-count-few": "перуанські соли (1863–1965)",
        "displayName-count-many": "перуанських солів (1863–1965)",
        "displayName-count-other": "перуанського сола (1863–1965)",
        symbol: "PES"
      },
      PGK: {
        displayName: "кіна Папуа-Нової Ґвінеї",
        "displayName-count-one": "кіна Папуа-Нової Ґвінеї",
        "displayName-count-few": "кіни Папуа-Нової Ґвінеї",
        "displayName-count-many": "кін Папуа-Нової Ґвінеї",
        "displayName-count-other": "кіни Папуа-Нової Ґвінеї",
        symbol: "PGK"
      },
      PHP: {
        displayName: "філіппінський песо",
        "displayName-count-one": "філіппінський песо",
        "displayName-count-few": "філіппінські песо",
        "displayName-count-many": "філіппінських песо",
        "displayName-count-other": "філіппінського песо",
        symbol: "PHP",
        "symbol-alt-narrow": "₱"
      },
      PKR: {
        displayName: "пакистанська рупія",
        "displayName-count-one": "пакистанська рупія",
        "displayName-count-few": "пакистанські рупії",
        "displayName-count-many": "пакистанських рупій",
        "displayName-count-other": "пакистанської рупії",
        symbol: "PKR",
        "symbol-alt-narrow": "Rs"
      },
      PLN: {
        displayName: "польський злотий",
        "displayName-count-one": "польський злотий",
        "displayName-count-few": "польські злоті",
        "displayName-count-many": "польських злотих",
        "displayName-count-other": "польського злотого",
        symbol: "PLN",
        "symbol-alt-narrow": "zł"
      },
      PLZ: {
        displayName: "польський злотий (1950–1995)",
        symbol: "PLZ"
      },
      PTE: {
        displayName: "португальський ескудо",
        symbol: "PTE"
      },
      PYG: {
        displayName: "парагвайський гуарані",
        "displayName-count-one": "парагвайський гуарані",
        "displayName-count-few": "парагвайські гуарані",
        "displayName-count-many": "парагвайських гуарані",
        "displayName-count-other": "парагвайського гуарані",
        symbol: "PYG",
        "symbol-alt-narrow": "₲"
      },
      QAR: {
        displayName: "катарський ріал",
        "displayName-count-one": "катарський ріал",
        "displayName-count-few": "катарські ріали",
        "displayName-count-many": "катарських ріалів",
        "displayName-count-other": "катарського ріала",
        symbol: "QAR"
      },
      RHD: {
        displayName: "родезійський долар",
        symbol: "RHD"
      },
      ROL: {
        displayName: "старий румунський лей",
        symbol: "ROL"
      },
      RON: {
        displayName: "румунський лей",
        "displayName-count-one": "румунський лей",
        "displayName-count-few": "румунські леї",
        "displayName-count-many": "румунських леїв",
        "displayName-count-other": "румунського лея",
        symbol: "RON",
        "symbol-alt-narrow": "lei"
      },
      RSD: {
        displayName: "сербський динар",
        "displayName-count-one": "сербський динар",
        "displayName-count-few": "сербські динари",
        "displayName-count-many": "сербських динарів",
        "displayName-count-other": "сербського динара",
        symbol: "RSD"
      },
      RUB: {
        displayName: "російський рубль",
        "displayName-count-one": "російський рубль",
        "displayName-count-few": "російські рублі",
        "displayName-count-many": "російських рублів",
        "displayName-count-other": "російського рубля",
        symbol: "RUB",
        "symbol-alt-narrow": "₽"
      },
      RUR: {
        displayName: "російський рубль (1991–1998)",
        "displayName-count-one": "російський рубль (RUR)",
        "displayName-count-few": "російські рублі (RUR)",
        "displayName-count-many": "російських рублів (RUR)",
        "displayName-count-other": "російського рубля (RUR)",
        symbol: "RUR",
        "symbol-alt-narrow": "р."
      },
      RWF: {
        displayName: "руандійський франк",
        "displayName-count-one": "руандійський франк",
        "displayName-count-few": "руандійські франки",
        "displayName-count-many": "руандійських франків",
        "displayName-count-other": "руандійського франка",
        symbol: "RWF",
        "symbol-alt-narrow": "RF"
      },
      SAR: {
        displayName: "саудівський ріал",
        "displayName-count-one": "саудівський ріал",
        "displayName-count-few": "саудівські ріали",
        "displayName-count-many": "саудівських ріалів",
        "displayName-count-other": "саудівського ріала",
        symbol: "SAR"
      },
      SBD: {
        displayName: "долар Соломонових Островів",
        "displayName-count-one": "долар Соломонових Островів",
        "displayName-count-few": "долари Соломонових Островів",
        "displayName-count-many": "доларів Соломонових Островів",
        "displayName-count-other": "долара Соломонових Островів",
        symbol: "SBD",
        "symbol-alt-narrow": "$"
      },
      SCR: {
        displayName: "сейшельська рупія",
        "displayName-count-one": "сейшельська рупія",
        "displayName-count-few": "сейшельські рупії",
        "displayName-count-many": "сейшельських рупій",
        "displayName-count-other": "сейшельської рупії",
        symbol: "SCR"
      },
      SDD: {
        displayName: "суданський динар",
        symbol: "SDD"
      },
      SDG: {
        displayName: "суданський фунт",
        "displayName-count-one": "суданський фунт",
        "displayName-count-few": "суданські фунти",
        "displayName-count-many": "суданських фунтів",
        "displayName-count-other": "суданського фунта",
        symbol: "SDG"
      },
      SDP: {
        displayName: "старий суданський фунт",
        symbol: "SDP"
      },
      SEK: {
        displayName: "шведська крона",
        "displayName-count-one": "шведська крона",
        "displayName-count-few": "шведські крони",
        "displayName-count-many": "шведських крон",
        "displayName-count-other": "шведської крони",
        symbol: "SEK",
        "symbol-alt-narrow": "kr"
      },
      SGD: {
        displayName: "сінгапурський долар",
        "displayName-count-one": "сінгапурський долар",
        "displayName-count-few": "сінгапурські долари",
        "displayName-count-many": "сінгапурських доларів",
        "displayName-count-other": "сінгапурського долара",
        symbol: "SGD",
        "symbol-alt-narrow": "$"
      },
      SHP: {
        displayName: "фунт острова Святої Єлени",
        "displayName-count-one": "фунт острова Святої Єлени",
        "displayName-count-few": "фунти острова Святої Єлени",
        "displayName-count-many": "фунтів острова Святої Єлени",
        "displayName-count-other": "фунта острова Святої Єлени",
        symbol: "SHP",
        "symbol-alt-narrow": "£"
      },
      SIT: {
        displayName: "словенський толар",
        symbol: "SIT"
      },
      SKK: {
        displayName: "словацька крона",
        symbol: "SKK"
      },
      SLE: {
        displayName: "SLE",
        symbol: "SLE"
      },
      SLL: {
        displayName: "леоне Сьєрра-Леоне",
        "displayName-count-one": "леоне Сьєрра-Леоне",
        "displayName-count-few": "леоне Сьєрра-Леоне",
        "displayName-count-many": "леоне Сьєрра-Леоне",
        "displayName-count-other": "леоне Сьєрра-Леоне",
        symbol: "SLL"
      },
      SOS: {
        displayName: "сомалійський шилінг",
        "displayName-count-one": "сомалійський шилінг",
        "displayName-count-few": "сомалійські шилінги",
        "displayName-count-many": "сомалійських шилінгів",
        "displayName-count-other": "сомалійського шилінга",
        symbol: "SOS"
      },
      SRD: {
        displayName: "суринамський долар",
        "displayName-count-one": "суринамський долар",
        "displayName-count-few": "суринамські долари",
        "displayName-count-many": "суринамських доларів",
        "displayName-count-other": "суринамського долара",
        symbol: "SRD",
        "symbol-alt-narrow": "$"
      },
      SRG: {
        displayName: "суринамський гульден",
        symbol: "SRG"
      },
      SSP: {
        displayName: "південносуданський фунт",
        "displayName-count-one": "південносуданський фунт",
        "displayName-count-few": "південносуданські фунти",
        "displayName-count-many": "південносуданських фунтів",
        "displayName-count-other": "південносуданського фунта",
        symbol: "SSP",
        "symbol-alt-narrow": "£"
      },
      STD: {
        displayName: "добра Сан-Томе і Прінсіпі (1977–2017)",
        "displayName-count-one": "добра Сан-Томе і Прінсіпі (1977–2017)",
        "displayName-count-few": "добри Сан-Томе і Принсіпі (1977–2017)",
        "displayName-count-many": "добр Сан-Томе і Принсіпі (1977–2017)",
        "displayName-count-other": "добри Сан-Томе і Прінсіпі (1977–2017)",
        symbol: "STD"
      },
      STN: {
        displayName: "добра Сан-Томе і Прінсіпі",
        "displayName-count-one": "добра Сан-Томе і Прінсіпі",
        "displayName-count-few": "добри Сан-Томе і Принсіпі",
        "displayName-count-many": "добр Сан-Томе і Принсіпі",
        "displayName-count-other": "добри Сан-Томе і Прінсіпі",
        symbol: "STN",
        "symbol-alt-narrow": "Db"
      },
      SUR: {
        displayName: "радянський рубль",
        "displayName-count-one": "радянський рубль",
        "displayName-count-few": "радянські рублі",
        "displayName-count-many": "радянських рублів",
        "displayName-count-other": "радянського рубля",
        symbol: "SUR"
      },
      SVC: {
        displayName: "сальвадорський колон",
        symbol: "SVC"
      },
      SYP: {
        displayName: "сирійський фунт",
        "displayName-count-one": "сирійський фунт",
        "displayName-count-few": "сирійські фунти",
        "displayName-count-many": "сирійських фунтів",
        "displayName-count-other": "сирійського фунта",
        symbol: "SYP",
        "symbol-alt-narrow": "£"
      },
      SZL: {
        displayName: "свазілендський лілангені",
        "displayName-count-one": "свазілендський лілангені",
        "displayName-count-few": "свазілендські лілангені",
        "displayName-count-many": "свазілендських лілангені",
        "displayName-count-other": "свазілендського лілангені",
        symbol: "SZL"
      },
      THB: {
        displayName: "таїландський бат",
        "displayName-count-one": "таїландський бат",
        "displayName-count-few": "таїландські бати",
        "displayName-count-many": "таїландських батів",
        "displayName-count-other": "таїландського бата",
        symbol: "THB",
        "symbol-alt-narrow": "฿"
      },
      TJR: {
        displayName: "таджицький рубль",
        symbol: "TJR"
      },
      TJS: {
        displayName: "таджицький сомоні",
        "displayName-count-one": "таджицький сомоні",
        "displayName-count-few": "таджицькі сомоні",
        "displayName-count-many": "таджицьких сомоні",
        "displayName-count-other": "таджицького сомоні",
        symbol: "TJS"
      },
      TMM: {
        displayName: "туркменський манат (1993–2009)",
        "displayName-count-one": "туркменський манат (1993–2009)",
        "displayName-count-few": "туркменські манати (1993–2009)",
        "displayName-count-many": "туркменських манатів (1993–2009)",
        "displayName-count-other": "туркменського маната (1993–2009)",
        symbol: "TMM"
      },
      TMT: {
        displayName: "туркменський манат",
        "displayName-count-one": "туркменський манат",
        "displayName-count-few": "туркменські манати",
        "displayName-count-many": "туркменських манатів",
        "displayName-count-other": "туркменського маната",
        symbol: "TMT"
      },
      TND: {
        displayName: "туніський динар",
        "displayName-count-one": "туніський динар",
        "displayName-count-few": "туніські динари",
        "displayName-count-many": "туніських динарів",
        "displayName-count-other": "туніського динара",
        symbol: "TND"
      },
      TOP: {
        displayName: "тонганська паанга",
        "displayName-count-one": "тонганська паанга",
        "displayName-count-few": "тонганські паанги",
        "displayName-count-many": "тонганських паанг",
        "displayName-count-other": "тонганської паанги",
        symbol: "TOP",
        "symbol-alt-narrow": "T$"
      },
      TPE: {
        displayName: "тіморський ескудо",
        symbol: "TPE"
      },
      TRL: {
        displayName: "турецька ліра (1922–2005)",
        "displayName-count-one": "турецька ліра (1922–2005)",
        "displayName-count-few": "турецькі ліри (1922–2005)",
        "displayName-count-many": "турецьких лір (1922–2005)",
        "displayName-count-other": "турецької ліри (1922–2005)",
        symbol: "TRL"
      },
      TRY: {
        displayName: "турецька ліра",
        "displayName-count-one": "турецька ліра",
        "displayName-count-few": "турецькі ліри",
        "displayName-count-many": "турецьких лір",
        "displayName-count-other": "турецької ліри",
        symbol: "TRY",
        "symbol-alt-narrow": "₺",
        "symbol-alt-variant": "TL"
      },
      TTD: {
        displayName: "долар Тринідаду і Тобаго",
        "displayName-count-one": "долар Тринідаду і Тобаго",
        "displayName-count-few": "долари Тринідаду і Тобаго",
        "displayName-count-many": "доларів Тринідаду і Тобаго",
        "displayName-count-other": "долара Тринідаду і Тобаго",
        symbol: "TTD",
        "symbol-alt-narrow": "$"
      },
      TWD: {
        displayName: "новий тайванський долар",
        "displayName-count-one": "новий тайванський долар",
        "displayName-count-few": "нові тайванські долари",
        "displayName-count-many": "нових тайванських доларів",
        "displayName-count-other": "нового тайванського долара",
        symbol: "TWD",
        "symbol-alt-narrow": "$"
      },
      TZS: {
        displayName: "танзанійський шилінг",
        "displayName-count-one": "танзанійський шилінг",
        "displayName-count-few": "танзанійські шилінги",
        "displayName-count-many": "танзанійських шилінгів",
        "displayName-count-other": "танзанійського шилінга",
        symbol: "TZS"
      },
      UAH: {
        displayName: "українська гривня",
        "displayName-count-one": "українська гривня",
        "displayName-count-few": "українські гривні",
        "displayName-count-many": "українських гривень",
        "displayName-count-other": "української гривні",
        symbol: "₴",
        "symbol-alt-narrow": "₴",
        "symbol-alt-variant": "грн"
      },
      UAK: {
        displayName: "український карбованець",
        "displayName-count-one": "український карбованець",
        "displayName-count-few": "українські карбованці",
        "displayName-count-many": "українських карбованців",
        "displayName-count-other": "українського карбованця",
        symbol: "крб."
      },
      UGS: {
        displayName: "угандійський шилінг (1966–1987)",
        symbol: "UGS"
      },
      UGX: {
        displayName: "угандійський шилінг",
        "displayName-count-one": "угандійський шилінг",
        "displayName-count-few": "угандійські шилінги",
        "displayName-count-many": "угандійських шилінгів",
        "displayName-count-other": "угандійського шилінга",
        symbol: "UGX"
      },
      USD: {
        displayName: "долар США",
        "displayName-count-one": "долар США",
        "displayName-count-few": "долари США",
        "displayName-count-many": "доларів США",
        "displayName-count-other": "долара США",
        symbol: "USD",
        "symbol-alt-narrow": "$"
      },
      USN: {
        displayName: "долар США (наступного дня)",
        symbol: "USN"
      },
      USS: {
        displayName: "долар США (цього дня)",
        symbol: "USS"
      },
      UYI: {
        displayName: "уругвайський песо в індексованих одиницях",
        symbol: "UYI"
      },
      UYP: {
        displayName: "уругвайське песо (1975–1993)",
        symbol: "UYP"
      },
      UYU: {
        displayName: "уругвайський песо",
        "displayName-count-one": "уругвайський песо",
        "displayName-count-few": "уругвайські песо",
        "displayName-count-many": "уругвайських песо",
        "displayName-count-other": "уругвайського песо",
        symbol: "UYU",
        "symbol-alt-narrow": "$"
      },
      UYW: {
        displayName: "UYW",
        symbol: "UYW"
      },
      UZS: {
        displayName: "узбецький сум",
        "displayName-count-one": "узбецький сум",
        "displayName-count-few": "узбецькі суми",
        "displayName-count-many": "узбецьких сумів",
        "displayName-count-other": "узбецького сума",
        symbol: "UZS"
      },
      VEB: {
        displayName: "венесуельський болівар (1871–2008)",
        symbol: "VEB"
      },
      VED: {
        displayName: "VED",
        symbol: "VED"
      },
      VEF: {
        displayName: "венесуельський болівар (2008–2018)",
        "displayName-count-one": "венесуельський болівар (2008–2018)",
        "displayName-count-few": "венесуельські болівари (2008–2018)",
        "displayName-count-many": "венесуельських боліварів (2008–2018)",
        "displayName-count-other": "венесуельського болівара (2008–2018)",
        symbol: "VEF",
        "symbol-alt-narrow": "Bs"
      },
      VES: {
        displayName: "венесуельський болівар",
        "displayName-count-one": "венесуельський болівар",
        "displayName-count-few": "венесуельські болівари",
        "displayName-count-many": "венесуельських боліварів",
        "displayName-count-other": "венесуельського болівара",
        symbol: "VES"
      },
      VND: {
        displayName: "вʼєтнамський донг",
        "displayName-count-one": "вʼєтнамський донг",
        "displayName-count-few": "вʼєтнамські донги",
        "displayName-count-many": "вʼєтнамських донгів",
        "displayName-count-other": "вʼєтнамського донга",
        symbol: "VND",
        "symbol-alt-narrow": "₫"
      },
      VNN: {
        displayName: "VNN",
        symbol: "VNN"
      },
      VUV: {
        displayName: "вануатський вату",
        "displayName-count-one": "вануатський вату",
        "displayName-count-few": "вануатські вату",
        "displayName-count-many": "вануатських вату",
        "displayName-count-other": "вануатського вату",
        symbol: "VUV"
      },
      WST: {
        displayName: "самоанська тала",
        "displayName-count-one": "самоанська тала",
        "displayName-count-few": "самоанські тали",
        "displayName-count-many": "самоанських тал",
        "displayName-count-other": "самоанської тали",
        symbol: "WST"
      },
      XAF: {
        displayName: "центральноафриканський франк",
        "displayName-count-one": "центральноафриканський франк",
        "displayName-count-few": "центральноафриканські франки",
        "displayName-count-many": "центральноафриканських франків",
        "displayName-count-other": "центральноафриканського франка",
        symbol: "FCFA"
      },
      XAG: {
        displayName: "срібло",
        symbol: "XAG"
      },
      XAU: {
        displayName: "золото",
        symbol: "XAU"
      },
      XBA: {
        displayName: "європейська складена валютна одиниця",
        symbol: "XBA"
      },
      XBB: {
        displayName: "одиниця європейського валютного фонду",
        symbol: "XBB"
      },
      XBC: {
        displayName: "європейська розрахункова одиниця XBC",
        symbol: "XBC"
      },
      XBD: {
        displayName: "європейська розрахункова одиниця XBD",
        symbol: "XBD"
      },
      XCD: {
        displayName: "східнокарибський долар",
        "displayName-count-one": "східнокарибський долар",
        "displayName-count-few": "східнокарибські долари",
        "displayName-count-many": "східнокарибських доларів",
        "displayName-count-other": "східнокарибського долара",
        symbol: "XCD",
        "symbol-alt-narrow": "$"
      },
      XDR: {
        displayName: "спеціальні права запозичення",
        symbol: "XDR"
      },
      XEU: {
        displayName: "європейська валютна одиниця",
        symbol: "XEU"
      },
      XFO: {
        displayName: "французький золотий франк",
        symbol: "XFO"
      },
      XFU: {
        displayName: "французький франк UIC",
        symbol: "XFU"
      },
      XOF: {
        displayName: "західноафриканський франк",
        "displayName-count-one": "західноафриканський франк",
        "displayName-count-few": "західноафриканські франки",
        "displayName-count-many": "західноафриканських франків",
        "displayName-count-other": "західноафриканського франка",
        symbol: "F CFA"
      },
      XPD: {
        displayName: "паладій",
        symbol: "XPD"
      },
      XPF: {
        displayName: "французький тихоокеанський франк",
        "displayName-count-one": "французький тихоокеанський франк",
        "displayName-count-few": "французькі тихоокеанські франки",
        "displayName-count-many": "французьких тихоокеанських франків",
        "displayName-count-other": "французького тихоокеанського франка",
        symbol: "CFPF"
      },
      XPT: {
        displayName: "платина",
        symbol: "XPT"
      },
      XRE: {
        displayName: "фонди RINET",
        symbol: "XRE"
      },
      XSU: {
        displayName: "XSU",
        symbol: "XSU"
      },
      XTS: {
        displayName: "код тестування валюти",
        symbol: "XTS"
      },
      XUA: {
        displayName: "XUA",
        symbol: "XUA"
      },
      XXX: {
        displayName: "невідома грошова одиниця",
        "displayName-count-one": "невідома грошова одиниця",
        "displayName-count-few": "невідомі грошові одиниці",
        "displayName-count-many": "невідомих грошових одиниць",
        "displayName-count-other": "невідомої грошової одиниці",
        symbol: "¤"
      },
      YDD: {
        displayName: "єменський динар",
        symbol: "YDD"
      },
      YER: {
        displayName: "єменський ріал",
        "displayName-count-one": "єменський ріал",
        "displayName-count-few": "єменські ріали",
        "displayName-count-many": "єменських ріалів",
        "displayName-count-other": "єменського ріала",
        symbol: "YER"
      },
      YUD: {
        displayName: "югославський твердий динар",
        symbol: "YUD"
      },
      YUM: {
        displayName: "югославський новий динар",
        symbol: "YUM"
      },
      YUN: {
        displayName: "югославський конвертований динар",
        symbol: "YUN"
      },
      YUR: {
        displayName: "YUR",
        symbol: "YUR"
      },
      ZAL: {
        displayName: "південноафриканський фінансовий ранд",
        symbol: "ZAL"
      },
      ZAR: {
        displayName: "південноафриканський ранд",
        "displayName-count-one": "південноафриканський ранд",
        "displayName-count-few": "південноафриканські ранди",
        "displayName-count-many": "південноафриканських рандів",
        "displayName-count-other": "південноафриканського ранда",
        symbol: "ZAR",
        "symbol-alt-narrow": "R"
      },
      ZMK: {
        displayName: "замбійська квача (1968–2012)",
        "displayName-count-one": "замбійська квача (1968–2012)",
        "displayName-count-few": "замбійські квачі (1968–2012)",
        "displayName-count-many": "замбійських квач (1968–2012)",
        "displayName-count-other": "замбійські квачі (1968–2012)",
        symbol: "ZMK"
      },
      ZMW: {
        displayName: "замбійська квача",
        "displayName-count-one": "замбійська квача",
        "displayName-count-few": "замбійські квачі",
        "displayName-count-many": "замбійських квач",
        "displayName-count-other": "замбійської квачі",
        symbol: "ZMW",
        "symbol-alt-narrow": "ZK"
      },
      ZRN: {
        displayName: "заїрський новий заїр",
        symbol: "ZRN"
      },
      ZRZ: {
        displayName: "заїрський заїр",
        symbol: "ZRZ"
      },
      ZWD: {
        displayName: "зімбабвійський долар",
        "displayName-count-one": "зімбабвійського долара",
        "displayName-count-few": "зімбабвійскі долари",
        "displayName-count-many": "зімбабвійських доларів",
        "displayName-count-other": "зімбабвійські долари",
        symbol: "ZWD"
      },
      ZWL: {
        displayName: "зімбабвійський долар (2009)",
        "displayName-count-one": "зімбабвійський долар (2009)",
        "displayName-count-few": "зімбабвійські долари (2009)",
        "displayName-count-many": "зімбабвійських доларів (2009)",
        "displayName-count-other": "зімбабвійського долара (2009)",
        symbol: "ZWL"
      },
      ZWR: {
        displayName: "ZWR",
        symbol: "ZWR"
      }
    },
    localeCurrency: "UAH"
  },
  calendar: {
    patterns: {
      d: "dd.MM.y",
      D: "EEEE, d MMMM y 'р'.",
      m: "d MMM",
      M: "d MMMM",
      y: "LLL y 'р'.",
      Y: "LLLL y 'р'.",
      F: "EEEE, d MMMM y 'р'. HH:mm:ss",
      g: "dd.MM.y HH:mm",
      G: "dd.MM.y HH:mm:ss",
      t: "HH:mm",
      T: "HH:mm:ss",
      s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
      u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'"
    },
    dateTimeFormats: {
      full: "{1} 'о' {0}",
      long: "{1} 'о' {0}",
      medium: "{1}, {0}",
      short: "{1}, {0}",
      availableFormats: {
        Bh: "h B",
        Bhm: "h:mm B",
        Bhms: "h:mm:ss B",
        d: "d",
        E: "ccc",
        EBhm: "E h:mm B",
        EBhms: "E h:mm:ss B",
        Ed: "E, d",
        Ehm: "E h:mm a",
        EHm: "E HH:mm",
        Ehms: "E h:mm:ss a",
        EHms: "E HH:mm:ss",
        Gy: "y G",
        GyMd: "dd-MM-y GGGGG",
        GyMMM: "LLL y 'р'. G",
        GyMMMd: "d MMM y 'р'. G",
        GyMMMEd: "E, d MMM y 'р'. G",
        h: "h a",
        H: "HH",
        hm: "h:mm a",
        Hm: "HH:mm",
        hms: "h:mm:ss a",
        Hms: "HH:mm:ss",
        hmsv: "h:mm:ss a v",
        Hmsv: "HH:mm:ss v",
        hmv: "h:mm a v",
        Hmv: "HH:mm v",
        M: "LL",
        Md: "dd.MM",
        MEd: "E, dd.MM",
        MMM: "LLL",
        MMMd: "d MMM",
        MMMEd: "E, d MMM",
        MMMMd: "d MMMM",
        MMMMEd: "E, d MMMM",
        "MMMMW-count-one": "W-'й' 'тиж'. MMMM",
        "MMMMW-count-few": "W-'й' 'тиж'. MMMM",
        "MMMMW-count-many": "W-'й' 'тиж'. MMMM",
        "MMMMW-count-other": "W-'й' 'тиж'. MMMM",
        ms: "mm:ss",
        y: "y",
        yM: "MM.y",
        yMd: "dd.MM.y",
        yMEd: "E, dd.MM.y",
        yMMM: "LLL y 'р'.",
        yMMMd: "d MMM y 'р'.",
        yMMMEd: "E, d MMM y 'р'.",
        yMMMM: "LLLL y 'р'.",
        yQQQ: "QQQ y",
        yQQQQ: "QQQQ y 'р'.",
        "yw-count-one": "w-'й' 'тиж'. Y 'р'.",
        "yw-count-few": "w-'й' 'тиж'. Y 'р'.",
        "yw-count-many": "w-'й' 'тиж'. Y 'р'.",
        "yw-count-other": "w-'й' 'тиж'. Y 'р'."
      }
    },
    timeFormats: {
      full: "HH:mm:ss zzzz",
      long: "HH:mm:ss z",
      medium: "HH:mm:ss",
      short: "HH:mm"
    },
    dateFormats: {
      full: "EEEE, d MMMM y 'р'.",
      long: "d MMMM y 'р'.",
      medium: "d MMM y 'р'.",
      short: "dd.MM.yy"
    },
    days: {
      format: {
        abbreviated: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
        narrow: ["Н", "П", "В", "С", "Ч", "П", "С"],
        short: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
        wide: ["неділя", "понеділок", "вівторок", "середа", "четвер", "пʼятниця", "субота"]
      },
      "stand-alone": {
        abbreviated: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
        narrow: ["Н", "П", "В", "С", "Ч", "П", "С"],
        short: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
        wide: ["неділя", "понеділок", "вівторок", "середа", "четвер", "пʼятниця", "субота"]
      }
    },
    months: {
      format: {
        abbreviated: ["січ.", "лют.", "бер.", "квіт.", "трав.", "черв.", "лип.", "серп.", "вер.", "жовт.", "лист.", "груд."],
        narrow: ["с", "л", "б", "к", "т", "ч", "л", "с", "в", "ж", "л", "г"],
        wide: ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"]
      },
      "stand-alone": {
        abbreviated: ["січ", "лют", "бер", "кві", "тра", "чер", "лип", "сер", "вер", "жов", "лис", "гру"],
        narrow: ["С", "Л", "Б", "К", "Т", "Ч", "Л", "С", "В", "Ж", "Л", "Г"],
        wide: ["січень", "лютий", "березень", "квітень", "травень", "червень", "липень", "серпень", "вересень", "жовтень", "листопад", "грудень"]
      }
    },
    quarters: {
      format: {
        abbreviated: ["1-й кв.", "2-й кв.", "3-й кв.", "4-й кв."],
        narrow: ["1", "2", "3", "4"],
        wide: ["1-й квартал", "2-й квартал", "3-й квартал", "4-й квартал"]
      },
      "stand-alone": {
        abbreviated: ["1-й кв.", "2-й кв.", "3-й кв.", "4-й кв."],
        narrow: ["1", "2", "3", "4"],
        wide: ["1-й квартал", "2-й квартал", "3-й квартал", "4-й квартал"]
      }
    },
    dayPeriods: {
      format: {
        abbreviated: {
          midnight: "опівночі",
          am: "дп",
          noon: "пополудні",
          pm: "пп",
          morning1: "ранку",
          afternoon1: "дня",
          evening1: "вечора",
          night1: "ночі"
        },
        narrow: {
          midnight: "північ",
          am: "дп",
          noon: "п",
          pm: "пп",
          morning1: "ранку",
          afternoon1: "дня",
          evening1: "вечора",
          night1: "ночі"
        },
        wide: {
          midnight: "опівночі",
          am: "дп",
          noon: "пополудні",
          pm: "пп",
          morning1: "ранку",
          afternoon1: "дня",
          evening1: "вечора",
          night1: "ночі"
        }
      },
      "stand-alone": {
        abbreviated: {
          midnight: "північ",
          am: "дп",
          noon: "полудень",
          pm: "пп",
          morning1: "ранок",
          afternoon1: "день",
          evening1: "вечір",
          night1: "ніч"
        },
        narrow: {
          midnight: "північ",
          am: "дп",
          noon: "полудень",
          pm: "пп",
          morning1: "ранок",
          afternoon1: "день",
          evening1: "вечір",
          night1: "ніч"
        },
        wide: {
          midnight: "північ",
          am: "дп",
          noon: "полудень",
          pm: "пп",
          morning1: "ранок",
          afternoon1: "день",
          evening1: "вечір",
          night1: "ніч"
        }
      }
    },
    eras: {
      format: {
        wide: {
          "0": "до нашої ери",
          "1": "нашої ери",
          "0-alt-variant": "до нової ери",
          "1-alt-variant": "нової ери"
        },
        abbreviated: {
          "0": "до н. е.",
          "1": "н. е.",
          "0-alt-variant": "до н. е.",
          "1-alt-variant": "н. е."
        },
        narrow: {
          "0": "до н.е.",
          "1": "н.е.",
          "0-alt-variant": "до н. е.",
          "1-alt-variant": "н. е."
        }
      }
    },
    gmtFormat: "GMT{0}",
    gmtZeroFormat: "GMT",
    dateFields: {
      era: {
        wide: "ера",
        short: "е.",
        narrow: "е"
      },
      year: {
        wide: "рік",
        short: "р.",
        narrow: "р"
      },
      quarter: {
        wide: "квартал",
        short: "кв.",
        narrow: "кв"
      },
      month: {
        wide: "місяць",
        short: "міс.",
        narrow: "м"
      },
      week: {
        wide: "тиждень",
        short: "тиж.",
        narrow: "т"
      },
      weekOfMonth: {
        wide: "тиждень місяця",
        short: "тиж. місяця",
        narrow: "тиж. міс."
      },
      day: {
        wide: "день",
        short: "д.",
        narrow: "д"
      },
      dayOfYear: {
        wide: "день року",
        short: "д. року",
        narrow: "день року"
      },
      weekday: {
        wide: "день тижня",
        short: "д. тижня",
        narrow: "д тижня"
      },
      weekdayOfMonth: {
        wide: "день місяця",
        short: "д. місяця",
        narrow: "д місяця"
      },
      dayperiod: {
        short: "дп/пп",
        wide: "дп/пп",
        narrow: "дп/пп"
      },
      hour: {
        wide: "година",
        short: "год.",
        narrow: "год"
      },
      minute: {
        wide: "хвилина",
        short: "хв.",
        narrow: "хв"
      },
      second: {
        wide: "секунда",
        short: "с",
        narrow: "с"
      },
      zone: {
        wide: "часовий пояс",
        short: "час. пояс",
        narrow: "час. п."
      },
      millisecond: {
        narrow: "мс",
        short: "мс",
        wide: "мілісекунда"
      }
    }
  },
  firstDay: 1,
  weekendRange: {
    start: 6,
    end: 0
  },
  likelySubtags: {
    uk: "uk-Cyrl-UA"
  }
});