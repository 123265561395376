import {Routes} from '@angular/router';

export const childrenGroupsRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/children-groups.component').then(m => m.ChildrenGroupsComponent)
  },
  {
    path: 'create-group',
    loadComponent: () => import('./pages/create-group/create-group.component').then(m => m.CreateGroupComponent)
  }
];
