import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Routes} from '@angular/router';
import {ScheduleNameService} from '@nit-core/services/utils/schedule-name.service';
import {ReplacementNameService} from '@nit-core/services/utils/replacement-name.service';
import {personalScheduleRouting} from './pages/personal-schedule/personal-schedule-routing';

const scheduleBreadcrumbResolver = (route: ActivatedRouteSnapshot) => {
  return inject(ScheduleNameService).getScheduleName(route.params.academicYearId);
};

const replacementBreadcrumbResolver = (route: ActivatedRouteSnapshot) => {
  return inject(ReplacementNameService).getReplacementName(route.params.entityId);
};

export const newTeacherScheduleRouting: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/new-teacher-schedule-main/new-teacher-schedule-main.component').then(m => m.NewTeacherScheduleMainComponent)
  },
  {
    path: 'create',
    data: {
      breadcrumb: 'Створення розкладу'
    },
    loadComponent: () => import('./pages/create-teacher-schedule/create-teacher-schedule.component').then(m => m.CreateTeacherScheduleComponent)
  },
  {
    path: 'edit/:id',
    data: {
      breadcrumb: 'Редагування розкладу'
    },
    loadComponent: () => import('./pages/create-teacher-schedule/create-teacher-schedule.component').then(m => m.CreateTeacherScheduleComponent)
  },
  {
    path: 'teachers-load',
    data: {
      breadcrumb: 'Навантаження вчителів'
    },
    loadComponent: () => import('./pages/teachers-load/teachers-load.component').then(m => m.TeachersLoadComponent)
  },
  {
    path: 'general',
    data: {
      breadcrumb: 'Загальний розклад'
    },
    loadComponent: () => import('./pages/grid-of-schedule/grid-of-schedule.component').then(m => m.GridOfScheduleComponent)
  },
  {
    path: ':academicYearId',
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/grid-of-schedule/grid-of-schedule.component').then(m => m.GridOfScheduleComponent)
      },
      {
        path: 'personal',
        data: {
          breadcrumb: 'Персональний розклад'
        },
        children: personalScheduleRouting
      },
      {
        path: 'add-lesson-for/:teacherId',
        data: {
          breadcrumb: 'Додавання уроку'
        },
        loadComponent: () => import('./pages/add-lesson/add-lesson.component').then(m => m.AddLessonComponent)
      },
      {
        path: 'edit-lesson/:lessonId/:classId/for/:teacherId',
        data: {
          breadcrumb: 'Редагування уроку'
        },
        loadComponent: () => import('./pages/add-lesson/add-lesson.component').then(m => m.AddLessonComponent)
      },
      {
        path: 'add-vacancy',
        data: {
          breadcrumb: 'Додати вакансію'
        },
        loadComponent: () => import('./pages/add-vacancy/add-vacancy.component').then(m => m.AddVacancyComponent)
      },
      {
        path: 'replace/:entityId',
        loadComponent: () => import('./pages/replace-teacher-or-vacancy/replace-teacher-or-vacancy.component').then(m => m.ReplaceTeacherOrVacancyComponent),
        resolve: {
          breadcrumb: replacementBreadcrumbResolver
        },
        data: {
          breadcrumb: ''
        },
      },
    ],
    resolve: {
      breadcrumb: scheduleBreadcrumbResolver
    },
    data: {
      breadcrumb: ''
    }
  }
];
